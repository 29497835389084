$(document).on('turbolinks:load', function() {
  var $rooms = $("select#room_id")
  var original = $rooms.html()
  $('select#facility_id').change(function() {
    var facility_id = $(this).val()
    $rooms.html(original).find('option').each(function() {
      if (facility_id != $(this).data('facility-id')) {
        $(this).not(':first-child').remove()
      }
    })
    if ($(this).val() == "") {
      $rooms.attr('disabled', 'disabled')
    } else {
      $rooms.removeAttr('disabled')
    }
    $('select#room_id').val('')
    $('#reservation_calendar').fullCalendar('refetchEvents')
    $('#raffle_calendar').fullCalendar('refetchEvents')
  })
})
