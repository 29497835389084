$(document).on('ready turbolinks:load', function() {
  $("input[name='reservation[request_for_exemption]'").change(function() {
    if ($(this).prop('checked')) {
      $("#reservation_payment_method").prop('disabled', true)
      $(".reservation_reason_for_exemption").slideDown()
      //$(".reservation_reason_for_exemption").css('visibility', 'visible')
    } else {
      $("#reservation_payment_method").prop('disabled', false)
      $(".reservation_reason_for_exemption").slideUp()
      //$(".reservation_reason_for_exemption").css('visibility', 'hidden')
    }
  })

  $("[data-payment-method-trigger]").on('change', function() {
    // 利用目的によっては料金が0になることがある
    const $target = $("[data-payment-method]");
    if ($(this).find(':selected').data('price') > 0) {
      $target.show();
      $target.find(':input').prop('disabled', false);
      $("input[name='reservation[request_for_exemption]']").trigger('change');
    } else {
      $target.hide();
      $target.find(':input').prop('disabled', true);
    }
  });
  $("[data-payment-method-trigger]").trigger('change');

  // 申請書作成ボタン
  $('[data-reservation-to-clipboard]').on('click', reservation_to_clipboard)

  // 繰り返しselectの変更で利用日、etcの表示を切り替える。
  $("select[name='reservation[multiple_reservation_attributes][repeat_type]']").on('change', function() {
    var repeat_type = $("select[name='reservation[multiple_reservation_attributes][repeat_type]']").val();
    switch (repeat_type) {
      case 'daily':
      case 'weekly':
      case 'monthly_nth_day':
      case 'monthly_nth_wday':
      case 'end_of_month':
        $(".single_reservation_using_date").slideUp();
        $(".multiple_reservation_starts_on").slideDown();
        $(".multiple_reservation_ends_on").slideDown();
        switch (repeat_type) {
          case 'weekly':
            $(".multiple_reservation_wdays").slideDown();
            // $(".multiple_reservation_mday").slideUp();
            $(".multiple_reservation_mweek_and_wday").slideUp();
            break;
          case 'monthly_nth_wday':
            $(".multiple_reservation_wdays").slideUp();
            // $(".multiple_reservation_mday").slideUp();
            $(".multiple_reservation_mweek_and_wday").slideDown();
            break;
          case 'monthly_nth_day':
            $(".multiple_reservation_wdays").slideUp();
            // $(".multiple_reservation_mday").slideDown();
            $(".multiple_reservation_mweek_and_wday").slideUp();
            break;
          case 'daily':
          case 'end_of_month':
          default:
            $(".multiple_reservation_wdays").slideUp();
            // $(".multiple_reservation_mday").slideUp();
            $(".multiple_reservation_mweek_and_wday").slideUp();
            break;
        }
        break;
      case 'none':
      default:
        $(".single_reservation_using_date").slideDown();
        $(".multiple_reservation_starts_on").slideUp();
        $(".multiple_reservation_ends_on").slideUp();
        $(".multiple_reservation_wdays").slideUp();
        // $(".multiple_reservation_mday").slideUp();
        $(".multiple_reservation_mweek_and_wday").slideUp();
        break;
    }
  })
  $("select[name='reservation[multiple_reservation_attributes][repeat_type]']").trigger('change');

  $('.new_reservation').submit(function(){
    start_at = $("select[name='reservation[starts_at]']").val();
    end_at = $("select[name='reservation[ends_at]']").val();
    if (start_at && end_at && start_at >= end_at) {
      alert('利用開始時間<利用終了時間で選択してください');
      return false;
    }
  });

  $('.new_reservation input, #fixture_select_form input').keypress(function(e){
    if ((e.which && e.which === 13) || (e.keyCode && e.keyCode === 13)) {
      return false;
    }
  })

  // 申請内容表示画面
  
  // 利用内容のチェックボックス全選択制御
  var do_all = ".edit_reservation thead#reservations th input#do_all";
  var do_id = ".edit_reservation tbody#reservations input[name='do_ids[]']:enabled";
  var do_id_checked = do_id + ":checked";
  if ($(do_id) && $(do_id).length > 1) {
    $(do_all).on('change', function() {
      $(do_id).prop('checked', this.checked);
    });

    $(do_id).on('change', function() {
      if ($(do_id_checked) && $(do_id)) {
        if ($(do_id_checked).length == $(do_id).length) {
          $(do_all).prop('checked', true);
        } else {
          $(do_all).prop('checked', false);
        }
      }
    });
    }
  $(do_all).trigger('change');

  $('#backgrounds .cancel').on('click', function() {
    $('#cancel_id').val($(this).data("cancel-id"));
    $('#cancel_queue').val($(this).data("cancel-queue"));
    return true;
  });
});

$(window).on('pageshow', function() {
  // これがないとブラウザの戻るで予約申請画面に来た場合おかしくなる
  $("[data-payment-method-trigger]").trigger('change');
  $("input[name='reservation[request_for_exemption]']").trigger('change');
  // $("select[name='reservation[starts_at]']").trigger('change');
  $("select[name='reservation[multiple_reservation_attributes][repeat_type]']").trigger('change');

  // 申請内容表示画面
  $(".edit_reservation tbody#reservations input[name='do_ids[]']").trigger('change');

  $("body.reservations.payment_select").css({'position': '','width': ''});
  $(".loader-container").hide();
});

// 予約情報をクリップボードへ入れる
// コピーした内容を入れたテキストエリアを一瞬documentに追加し、
// テキストを選択、コピーコマンドを実行 を実施する必要がある
function reservation_to_clipboard(){
  var id = $(this).attr('id').slice('download'.length + 1);
  const userAgent = window.navigator.userAgent.toLowerCase();
  const $copydata = $('#copydata_'.concat(id));
  const $copytmp = $('<textarea></textarea>');
  // 減免率入力フォームが画面にある場合はその値を使う
  const $exemption_percent = $('select[name="reservation[exemption_percent]"]');
  if($exemption_percent[0]){
    $copydata.find('.exemption_percent').text($exemption_percent.find(':selected').val());
  }
  $copytmp.html($copydata.find('td').map(function(i, el){return $(el).text().trim()}).get().join('\n'));
  $('body').append($copytmp);
  if(userAgent.indexOf('chrome') != -1 ){
    $copytmp.select();
  } else {
    const range = document.createRange();
    range.selectNode($copytmp[0].firstChild);
    const selection = window.getSelection();
    selection.removeAllRanges();
    selection.addRange(range);
  }
  document.execCommand('copy');
  $copytmp.remove();
  alert('申請書に必要なデータをコピーしました\n申請書の所定の位置に貼り付けてください');
}
