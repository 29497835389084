import { preventDefault } from "fullcalendar";

$(document).on('turbolinks:load', function() {

  if($('.header--sp').css('display') == 'block') {
    $('a.burger--sp').on('click', function(event) {
      event.preventDefault();
      $('body').toggleClass('slideout');
      $(this).toggleClass('open');
    })
  }

  // if ($('.header--sp').css('display') == 'block') {
  //   // sidenav switch
  //   $('a.burger--sp').on('click', function() {
  //     $('body').addClass('slideout')
  //     return false
  //   })
  //   $('.nav-mask--sp').on('click', function() {
  //     $('body').removeClass('slideout')
  //     return false
  //   })
  // }

  /*
  if ($('.header--sp').css('display') == 'block' || $('body').hasClass('welcome index')) {
    // guest-flow modal
    $('.guest-flow-trigger').on('click', function() {
      if ($('.header--sp').css('display') == 'block') {
        // sidenav switch
        $('body').removeClass('slideout')
        console.log("slideout removed 2")
      }
      $('body').addClass('paper')
      console.log("paper added")
      $('#guest-flow').modal()
    })
    $('.close-guest-flow').on('click', function() {
      $('body').removeClass('paper')
      console.log("paper removed")
    })
  }*/

});
