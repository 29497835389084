$(document).on('ready turbolinks:load', function() {

    $("select[name='user[raffle_limit_type]']").change(function() {
      var val = $("select[name='user[raffle_limit_type]']").val();
      if (val != "none") {
        $(".user_raffle_limit_number").slideDown()
      } else {
        $(".user_raffle_limit_number").slideUp()
      }
    })
    $("select[name='user[raffle_limit_type]']").trigger('change');

    $("select[name='user[reservation_limit_type]']").change(function() {
      var val = $("select[name='user[reservation_limit_type]']").val();
      if (val != "none") {
        $(".user_reservation_limit_number").slideDown()
      } else {
        $(".user_reservation_limit_number").slideUp()
      }
    })
    $("select[name='user[reservation_limit_type]']").trigger('change');

    $("input[name='facility_use_setting']").change(function() {
      if ($("input[name='facility_use_setting']").prop("checked")) {
        $(".facility_use_list").slideDown()
      } else {
        $(".facility_use_list").slideUp()
      }
    })
    $("input[name='facility_use_setting']").trigger('change');
});

$(window).on('pageshow', function() {
    $("select[name='user[raffle_limit_type]']").trigger('change');
    $("select[name='user[reservation_limit_type]']").trigger('change');
    $("input[name='facility_use_setting']").trigger('change');
});