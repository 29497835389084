$(document).on('turbolinks:load', function() {

  const XlsxPopulate = require('xlsx-populate')

  function getWorkbook(url) {
    var Promise = XlsxPopulate.Promise
    return new Promise(function(resolve, reject) {
      var req = new XMLHttpRequest()
      req.open("GET", url, true)
      req.responseType = "arraybuffer"
      req.onreadystatechange = function() {
        if (req.readyState === 4) {
          if (req.status === 200) {
            resolve(XlsxPopulate.fromDataAsync(req.response))
          } else {
            reject("Received a " + req.status + " HTTP code.")
          }
        }
      }
      req.send()
    })
  }
  function generate(url, params, type) {
    return getWorkbook(url)
      .then(function(workbook) {
        var sheet = workbook.sheet(0)
        for (const k in params) {
          var cells = sheet.find(k)
          for (var cell of cells) {
            const rowNumber = cell.row().rowNumber()
            sheet.cell(rowNumber, 2).value(params[k])
          }
        }
        return workbook.outputAsync({ type: type })
      })
  }
  function generateBlob(url, params, filename) {
    return generate(url, params)
      .then(function(blob) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          window.navigator.msSaveOrOpenBlob(blob, "outA.xlsx")
        } else {
          var url = window.URL.createObjectURL(blob)
          var a = document.createElement("a")
          document.body.appendChild(a)
          a.href = url
          a.download = filename
          a.click()
          window.URL.revokeObjectURL(url)
          document.body.removeChild(a)
        }
      })
      .catch(function(err) {
        alert(err.message || err)
        throw err
      })
  }
  function generateBase64(url, params, filename) {
    return generate(url, params, "base64")
      .then(function(base64) {
        if (window.navigator && window.navigator.msSaveOrOpenBlob) {
          throw new Error("Navigating to data URI is not supported in IE.")
        } else {
          location.href = "data:" + XlsxPopulate.MIME_TYPE + ";base64," + base64
        }
      })
      .catch(function(err) {
        alert(err.message || err)
        throw err
      })
  }
  $(".download-document").on("click", function() {
    var url = $(this).attr('url')
    var filename = $(this).attr('filename')
    var params = {}
    $(".card-body.reservation .row.form").each(function(i, elem) {
      const k = $(elem).find(".key").text()
      const v = $(elem).find(".value").text()

      console.log("k["+k+"],v["+v+"]")

      params[k] = v
    })
    generateBlob(url, params, filename)
    //generateBase64(url, params)
  })
})
