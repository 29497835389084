$(document).on('turbolinks:load', function() {
  $('input#date_week_select').change(function () {
    var prop = $('input#date_week_select').prop('checked');
    // もしpropがチェック状態だったら
    if (prop) {
      $(".optional_price_date_type").slideUp()
      $(".optional_price_week_type").slideDown()
    } else {
      $(".optional_price_date_type").slideDown()
      $(".optional_price_week_type").slideUp()
    }
  })

  $('#data_save').on('click', function(){
    var start_on = document.getElementById('start_on');
    var start_on_value = start_on.value;
    var start_on_min = start_on.min;
    if (start_on_value >= start_on_min) {
      var prop = $('input#date_week_select').prop('checked');
      // もしpropがチェック状態だったら
      if (prop) {
        let el_yen = document.getElementsByClassName("week_yen");
        let chk_yen = "";
        for (let i = 0; i < el_yen.length; i++) {
          if (el_yen[i].value != null && el_yen[i].value != '' && Number(el_yen[i].value) >= 0) { 
            var eos1 = document.getElementsByClassName('week_open_seconds');
            let array_os = ['', '', '', '', '', '', '', '', '', '', '', '', '', ''];
            for (let j = 0; j < eos1.length; j++) {
              array_os[j] = eos1[j].value;
            }    

            var ecs1 = document.getElementsByClassName('week_close_seconds');
            let array_cs = ['', '', '', '', '', '', '', '', '', '', '', '', '', ''];
            for (let j = 0; j < ecs1.length; j++) {
              array_cs[j] = ecs1[j].value;
            }   

            let st = Number(array_os[i*2]) * 3600 + Number(array_os[i*2+1]) * 60;
            let ed = Number(array_cs[i*2]) * 3600 + Number(array_cs[i*2+1]) * 60;

            if (st >= ed) {
              alert('閉館時間 は、開館時間より後の時間を指定してください。')
              return false;             
            }

            let su = document.getElementsByClassName('select_unit');
            if (su[1].value == 'quarter_hour') {
              if (ed - st < 15 * 60) {
                alert('開館時間と開館時間は15分以上間隔を開けてください。')
                return false;  
              }
            } else if (su[1].value == 'half_hour') {
              if (ed - st < 30 * 60) {
                alert('開館時間と開館時間は30分以上間隔を開けてください。')
                return false;  
              }
            } else if (su[1].value == 'one_hour') {
              if (ed - st < 60 * 60) {
                alert('開館時間と開館時間は１時間以上間隔を開けてください。')
                return false;  
              }
            }

            chk_yen = chk_yen + el_yen[i].value;
          }
        }

        let el_unit_seconds = document.getElementsByClassName("date_unit_seconds");
        for (let i = 0; i < el_unit_seconds.length; i++) { 
          if (el_unit_seconds[i].value != 'quarter_hour' && el_unit_seconds[i].value != 'half_hour' && el_unit_seconds[i].value != 'one_hour') {
            alert('単位時間 は正しい単位時間ではありません。')
            return false;
          }
        }

        let el_max_yen = document.getElementsByClassName("week_max_yen");
        for (let i = 0; i < el_max_yen.length; i++) {
          if (isNaN(el_max_yen[i].value) == true)
          {
            alert('最大料金(円) を整数で指定してください。')
            return false;
          } 
          if (el_max_yen[i].value < 0) {
            alert('最大料金は 0 以上を指定してください。')
            return false;
          }
        }

        if (chk_yen) {
          var eos1 = document.getElementsByClassName('date_open_seconds');
          for (let i = 0; i < eos1.length; i++) {
            eos1[i].value = "00";
          }
      
          var ecs1 = document.getElementsByClassName('date_close_seconds');
          for (let i = 0; i < ecs1.length; i++) {
            ecs1[i].value = "00";
          }   
      
          var el1 = document.getElementsByClassName('date_yen');
          for (let i = 0; i < el1.length; i++) {
            el1[i].value = '';
          }
        } else {
          alert('単位時間あたりの料金(円)が入力されていません。')
          return false;
        }
      } else {
        let el_yen = document.getElementsByClassName("date_yen");
        let chk_yen = "";
        for (let i = 0; i < el_yen.length; i++) {
          if (el_yen[i].value != null && el_yen[i].value != '' && Number(el_yen[i].value) >= 0) {
            var eos1 = document.getElementsByClassName('date_open_seconds');
            let array_os = ['', '', '', ''];
            for (let j = 0; j < eos1.length; j++) {
              array_os[j] = eos1[j].value;
            }    

            var ecs1 = document.getElementsByClassName('date_close_seconds');
            let array_cs = ['', '', '', ''];
            for (let j = 0; j < ecs1.length; j++) {
              array_cs[j] = ecs1[j].value;
            }   

            let st = Number(array_os[i*2]) * 3600 + Number(array_os[i*2+1]) * 60;
            let ed = Number(array_cs[i*2]) * 3600 + Number(array_cs[i*2+1]) * 60;

            if (st >= ed) {
              alert('閉館時間 は、開館時間より後の時間を指定してください。')
              return false;             
            }

            let su = document.getElementsByClassName('select_unit');
            if (su[0].value == 'quarter_hour') {
              if (ed - st < 15 * 60) {
                alert('開館時間と開館時間は15分以上間隔を開けてください。')
                return false;  
              }
            } else if (su[0].value == 'half_hour') {
              if (ed - st < 30 * 60) {
                alert('開館時間と開館時間は30分以上間隔を開けてください。')
                return false;  
              }
            } else if (su[0].value == 'one_hour') {
              if (ed - st < 60 * 60) {
                alert('開館時間と開館時間は１時間以上間隔を開けてください。')
                return false;  
              }
            }

            chk_yen = chk_yen + el_yen[i].value;
          }
        }

        let el_unit_seconds = document.getElementsByClassName("date_unit_seconds");
        for (let i = 0; i < el_unit_seconds.length; i++) { 
          if (el_unit_seconds[i].value != 'quarter_hour' && el_unit_seconds[i].value != 'half_hour' && el_unit_seconds[i].value != 'one_hour') {
            alert('単位時間 は正しい単位時間ではありません。')
            return false;
          }
        }

        let el_max_yen = document.getElementsByClassName("date_max_yen");
        for (let i = 0; i < el_max_yen.length; i++) {        
          if (isNaN(el_max_yen[i].value) == true)
          {
            alert('最大料金(円) を整数で指定してください。')
            return false;
          } 
          if (el_max_yen[i].value < 0) {
            alert('最大料金は 0 以上を指定してください。')
            return false;
          }
        }

        if (chk_yen) {
          // 平日/休日の開館時間を平日なら月曜から金曜・休日なら土曜から日曜へ代入
          var eos1 = document.getElementsByClassName('date_open_seconds');
          let array_os = ['', '', '', ''];
          for (let i = 0; i < eos1.length; i++) {
            array_os[i] = eos1[i].value;
            eos1[i].value = "00";
          }    

          var eos2 = document.getElementsByClassName('week_open_seconds');
          for (let i = 0; i < eos2.length; i++) {
            if (i < 10)
            {
              if (i % 2 == 0)
              {
                eos2[i].value = array_os[0];
              } else {
                eos2[i].value = array_os[1];
              }
            } else {
              if (i % 2 == 0)
              {
                eos2[i].value = array_os[2];
              } else {
                eos2[i].value = array_os[3];
              }
            }
          }

          // 平日/休日の閉館時間を平日なら月曜から金曜・休日なら土曜から日曜へ代入
          var ecs1 = document.getElementsByClassName('date_close_seconds');
          let array_cs = ['', '', '', ''];
          for (let i = 0; i < ecs1.length; i++) {
            array_cs[i] = ecs1[i].value;
            ecs1[i].value = "00"
          }      

          var ecs2 = document.getElementsByClassName('week_close_seconds');
          for (let i = 0; i < ecs2.length; i++) {
            if (i < 10)
            {
              if (i % 2 == 0)
              {
                ecs2[i].value = array_cs[0];
              } else {
                ecs2[i].value = array_cs[1];
              }
            } else {
              if (i % 2 == 0)
              {
                ecs2[i].value = array_cs[2];
              } else {
                ecs2[i].value = array_cs[3];
              }
            }
          }

          // 平日/休日の1時間あたりの料金を平日なら月曜から金曜・休日なら土曜から日曜へ代入
          var el1_unit = document.getElementsByClassName('date_unit_seconds');
          let array_unit = ['', ''];
          for (let i = 0; i < el1_unit.length; i++) {
            array_unit[i] = el1_unit[i].value
            el1_unit[i].value = '';
          }

          var el2_unit = document.getElementsByClassName('week_unit_seconds');
          for (let i = 0; i < el2_unit.length; i++) {
            if (i < 5) {
              el2_unit[i].value = array_unit[0];
            } else {
              el2_unit[i].value = array_unit[1];
            }
          }

          // 平日/休日の1時間あたりの料金を平日なら月曜から金曜・休日なら土曜から日曜へ代入
          var el1 = document.getElementsByClassName('date_yen');
          let array = [0, 0];
          for (let i = 0; i < el1.length; i++) {
            array[i] = el1[i].value;
            el1[i].value = '';
          }

          var el2 = document.getElementsByClassName('week_yen');
          for (let i = 0; i < el2.length; i++) {
            if (i < 5)
            {
              el2[i].value = array[0];
            } else {
              el2[i].value = array[1];
            }
          }
         
          // 平日/休日の最大料金を平日なら月曜から金曜・休日なら土曜から日曜へ代入
          let el1_max_yen = document.getElementsByClassName("date_max_yen");
          let array_max_yen = [0, 0];
          for (let i = 0; i < el1_max_yen.length; i++) {
            array_max_yen[i] = el1_max_yen[i].value;
            el1_max_yen[i].value = '';
          }

          var el2_max_yen = document.getElementsByClassName('week_max_yen');
          for (let i = 0; i < el2_max_yen.length; i++) {
            if (i < 5)
            {
              el2_max_yen[i].value = array_max_yen[0];
            } else {
              el2_max_yen[i].value = array_max_yen[1];
            }
          }
        } else {
          alert('単位時間あたりの料金(円)が入力されていません。')
          return false;
        }
      }
    } 
  })

  $(window).on('pageshow', function() {
    $('input#date_week_select').trigger('change');

    var btns = document.querySelectorAll('#btn_clear');
    for(var i = 0; i < btns.length; i++){
        btns[i].addEventListener('click',function(){  
            var res = confirm("クリアしますか？"); 
            if (res) {         
              const result = $(this).attr('name');
              var date_type = { weekday: 0, holiday: 1, monday: 2, tuesday: 3, wednesday: 4, thursday: 5, friday: 6, saturday: 7, sunday: 8 };
              var index = date_type[result];
            
              if (result == "weekday" || result == "holiday") {
                var el11 = document.getElementsByClassName('date_open_seconds');
                var el12 = document.getElementsByClassName('date_close_seconds');
                var el13 = document.getElementsByClassName('date_unit_seconds');
                var el14 = document.getElementsByClassName('date_yen');
                var el15 = document.getElementsByClassName('date_max_yen');
            
                el11[index * 2].value = "00";
                el11[index * 2 + 1].value = "00";
                el12[index * 2].value = "00";
                el12[index * 2 + 1].value = "00";
                el13[index].value = 'one_hour';
                el14[index].value = '';
                el15[index].value = '';
              } else {
                index -= 2;
                var el21 = document.getElementsByClassName('week_open_seconds');
                var el22 = document.getElementsByClassName('week_close_seconds');
                var el23 = document.getElementsByClassName('week_unit_seconds');
                var el24 = document.getElementsByClassName('week_yen');
                var el25 = document.getElementsByClassName('week_max_yen');
            
                el21[index * 2].value = "00";
                el21[index * 2 + 1].value = "00";
                el22[index * 2].value = "00";
                el22[index * 2 + 1].value = "00";
                el23[index].value = 'one_hour';
                el24[index].value = '';
                el25[index].value = '';
              }
            }
        },false);
    }
  })

  if (g_prices.is_optional_prices_show) {
    if (g_prices.is_select_price) {
      // 時間あたり価格表 選択画面
      window.g_prices.show_render_optional_prices = function (prices) {
        const optional_price_by_date_type = (prices, date_type) => prices.find(price => price.date_type == date_type);
        const optional_prices_show_tr = no => $('tr.optional_prices_show_' + no);
        // g_prices.date_types順に表示
        for (i = 0; i < g_prices.date_types.length; i++) {
          const tr = optional_prices_show_tr(i);
          if (!tr) continue;
          if (i >= prices.length) {
            tr.hide();
            continue;
          }
          tr.show();
          const price = optional_price_by_date_type(prices, g_prices.date_types[i]);
          tr.find('.start_on').text(price.start_on);
          // date_type文字列を日本語に変換
          const date_type_jp = optional_price_date_types[price.date_type];
          tr.find('.date_type').text(date_type_jp);
          tr.find('.open_seconds').text(price.open_time);
          tr.find('.close_seconds').text(price.close_time);
          tr.find('.unit_seconds').text(price.unit_seconds);
          tr.find('.yen').text(price.yen);
          tr.find('.max_yen').text(price.max_yen);
        }
      }

      //  時間あたり価格表 選択画面 初期化
      //  (価格表表示 詳細画面はJSによる初期化不要)
      g_prices.select_prices_init();
    }
  }

  // 時間あたり価格表 新規作成
  if (g_prices.is_optional_prices_edit) {
    const date_types_week = g_prices.date_types;
    const date_types_date = ['weekday', 'holiday'];

    const checkbox_date_week_select = $('input#date_week_select');
    const tr_fields_optional_price = date_type => $('tr#fields_optional_price_' + date_type);

    const time_select_column = {hour: '(4i)', min: '(5i)'};
    const selector_optional_price_time_select = (field, column) => 'select[name="input[optional_prices][][' + field + '][][' + field + time_select_column[column] +']"]';
    const selector_optional_price = (field) => 'input[name="input[optional_prices][][' + field + ']"]';
    const input_optional_price = (date_type, field) => tr_fields_optional_price(date_type).find(selector_optional_price(field));
    const selector_optional_price_unit_seconds = 'select[name="optional_price_collection[optional_prices][][unit_seconds]"]';
    const select_optional_price_unit_seconds = (date_type) => tr_fields_optional_price(date_type).find(selector_optional_price_unit_seconds);

    const time_select_optional_price_exists = (date_type, field) => (
      (tr_fields_optional_price(date_type).find(selector_optional_price_time_select(field, 'hour')).val() != '00') ||
      (tr_fields_optional_price(date_type).find(selector_optional_price_time_select(field, 'min')).val() != '00')
    );

    // 時間あたり価格表が編集中かどうか
    window.g_prices.optional_prices_is_editing = function () {
      const date_types = checkbox_date_week_select.prop('checked') ? date_types_week : date_types_date;
      return (date_types.some(date_type => (
        time_select_optional_price_exists(date_type, 'open_seconds') ||
        time_select_optional_price_exists(date_type, 'close_seconds') ||
        (select_optional_price_unit_seconds(date_type).val() != 'one_hour') ||
        (input_optional_price(date_type, 'yen').val() != '') ||
        (input_optional_price(date_type, 'max_yen').val() != '')
      )));
    };

    // 初期化

    // 「既存の価格表から作成」ボタン
    $('#select_prices').on('click', function () {
      return g_prices.confirm_editing();
    });
  }
});

$(document).on('turbolinks:before-cache', function() {
  if (g_prices.is_optional_prices_show) {
    if (g_prices.is_select_price) {
      // 時間あたり価格表 選択
      g_prices.select_prices_fini();
    }
  }
});
