$(document).on('turbolinks:load', function() {
  const add_all_users = function (reservation_take_method, data) {
    const all_users = {id: 'all', text: '特定のコマを抽選対象外にする'};
    let results = data.results;
    if (reservation_take_method == 'raffle') {
      results.unshift(all_users);
    }
    return {
      results: results
    };
  };

  $(this).find('[data-user-select2]').select2({
    ajax: {
      url: '/user_resources/search',
      dataType: 'json',
      processResults: (data) => {
        let reservation_take_method = $('#reservation_take_method').data('reservation-take-method');
        return add_all_users(reservation_take_method, data);
      }
    }
  });

  $(this).find('[data-raffle-user-select2]').select2({
    ajax: {
      url: '/raffle_applications/search',
      dataType: 'json',
      data: (params) =>{
        return {
          q: params.term,
          raffle_id: $("input[name='raffle_application[id]']").val()
        }
      }
    }
  });

  $('[id^="reservation_modal_"]').on('shown.bs.modal', function (e) {
    let reservation_take_method = $(this).data('reservation-take-method');
    $(this).find('[data-modal-user-select2]').select2({
      ajax: {
        url: '/user_resources/search',
        dataType: 'json',
        processResults: (data) => { return add_all_users(reservation_take_method, data); }
      },
      dropdownParent: $(this).find('.modal-body')
    });
  })

  $('[id^="reservation_modal_"]').on('hidden.bs.modal', function (e) {
    $(this).find('[data-modal-user-select2]').select2('destroy');
  });
});

$(document).on('turbolinks:before-cache', function() {
  $(this).find('[data-raffle-user-select2]').select2('destroy');
});
