$(document).on('turbolinks:load', function() {
  // 価格表のどの画面で動作しているか確認
  // 他のpacks/xxxx.jsから使用するためにブラウザグローバル変数windowに保存
  window.g_prices = {
    // 時間枠価格表 新規作成
    is_prices_edit: $('.price-calendar-create').length > 0,
    // 時間枠価格表 詳細 または 時間枠価格表の選択
    is_prices_show: $('.price-calendar-show').length > 0,
    // 時間あたり価格表 新規作成
    is_optional_prices_edit: $('.optional_price-new').length > 0,
    // 時間あたり価格表 詳細 または 時間あたり価格表の選択
    is_optional_prices_show: $('.optional_price-show').length > 0,
    // 価格表の選択であるか 価格表 詳細であるか
    is_select_price: $('#select_price_facility').length > 0,

    // 表示順
    date_types: ['monday', 'tuesday', 'wednesday', 'thursday', 'friday', 'saturday', 'sunday'],
  };

  // 価格表 詳細 / 選択 / 新規作成 画面
  if (g_prices.is_prices_edit || g_prices.is_prices_show || g_prices.is_optional_prices_edit || g_prices.is_optional_prices_show) {
    const reservation_method = (g_prices.is_prices_edit || g_prices.is_prices_show) ? 'admin' : 'optional';
    const prices_name = (reservation_method == 'admin') ? 'prices' : 'optional_prices';
    const prices_edit_action = (reservation_method == 'admin') ? 'calendar' : 'new';
    const prices_show_json_path = (room_id, start_on_value) => (
      '/rooms/' + room_id + '/' + prices_name + '/' + start_on_value + '.json'
    );
    const prices_edit_path = (room_id, source_room_id, start_on_value) => (
      '/rooms/' + room_id + '/' + prices_name + '/' + prices_edit_action + '?source=' + source_room_id + '&start_on=' + start_on_value
    );

    // 価格表 詳細 / 選択 画面
    if(g_prices.is_prices_show || g_prices.is_optional_prices_show) {
      window.g_prices.show_update_prices = function (room_id, start_on_value) {
        $.getJSON(
          prices_show_json_path(room_id, start_on_value),
          function (prices) {
            switch (reservation_method) {
              case 'admin':
                // price_calendar_readonly.jsに定義
                g_prices.show_render_calendar(prices);
                break;
              case 'optional':
                // optional_price.jsに定義
                g_prices.show_render_optional_prices(prices);
                break;
            }
          }
        );
      };

      // 価格表 選択 (_select_price.html)
      if (g_prices.is_select_price) {
        const select_price_facility = $('#select_price_facility');
        const select_price_room = $('#select_price_room');
        const select_price_start_on = $('#select_price_start_on');
        const button_copy_selected_price = $('#copy_selected_price');

        const select_price_facility_save = $('#select_price_facility_save');
        const select_price_room_save = $('#select_price_room_save');
        const select_price_start_on_save = $('#select_price_start_on_save');

        // 価格表の選択 初期化処理

        // _select_price.html.slimで設定されているprices_edit_room_id, select_price_availablesを使用
        window.g_prices.select_prices_init = function() {
          const prices_show_update_prices = function (facility_id, room_id, start_on_value) {
            g_prices.show_update_prices(room_id, start_on_value);
          };
          const set_select_option = function (select, option_data, default_value) {
            select.empty();
            for (data of option_data) {
              let newOption = new Option(data.text, data.value, false, false);
              select.append(newOption);
            }
            select.val(default_value);
            select.trigger('change');
          };
          const facility_rooms = (facility_id) => select_price_availables[facility_id].rooms;
          const room_start_on = (facility_id, room_id) => select_price_availables[facility_id].room[room_id].start_on;

          // ブラウザ「戻る」→「進む」で選択肢値が不当になる問題(turbolinksが画面更新時select2.jsの選択値を変更してしまう)対処
          // 保存しておいたものを復元
          if (select_price_facility_save.val() != '') {
            select_price_facility.val(select_price_facility_save.val());
          }
          if (select_price_room_save.val() != '') {
            select_price_room.val(select_price_room_save.val());
          }
          if (select_price_start_on_save.val() != '') {
            select_price_start_on.val(select_price_start_on_save.val());
          }

          // セレクトボックスの初期化

          // 施設

          select_price_facility.select2();
          select_price_facility.on('select2:select', function (e) {
            const data = e.params.data;

            const facility_id = data.id;
            const room_id = facility_rooms(facility_id)[0].value;
            const start_on = room_start_on(facility_id, room_id);
            const start_on_value = start_on[start_on.length - 1].value;

            set_select_option(select_price_room, facility_rooms(facility_id), room_id);
            set_select_option(select_price_start_on, start_on, start_on_value);
            prices_show_update_prices(facility_id, room_id, start_on_value);
          });

          // 部屋

          select_price_room.select2();
          select_price_room.on('select2:select', function (e) {
            const data = e.params.data;

            const facility_id = select_price_facility.val();
            const room_id = data.id;
            const start_on = room_start_on(facility_id, room_id);
            const start_on_value = start_on[start_on.length - 1].value;

            set_select_option(select_price_start_on, start_on, start_on_value);
            prices_show_update_prices(facility_id, room_id, start_on_value);
          });

          // 反映日

          select_price_start_on.select2();
          select_price_start_on.on('select2:select', function (e) {
            const data = e.params.data;

            const facility_id = select_price_facility.val();
            const room_id = select_price_room.val();
            const start_on_value = data.id;

            prices_show_update_prices(facility_id, room_id, start_on_value);
          });

          // 決定ボタン

          button_copy_selected_price.on('click', function (e) {
            e.preventDefault();
            e.stopPropagation();
            e.stopImmediatePropagation();

            const room_id = select_price_room.val();
            const start_on_value = select_price_start_on.val();

            location.href = prices_edit_path(prices_edit_room_id, room_id, start_on_value);
          });

          // 価格表の選択 施設/部屋/反映日セレクトボックス値によるカレンダー(時間枠部屋)または表(時間あたり部屋)の初期描画

          prices_show_update_prices(select_price_facility.val(), select_price_room.val(), select_price_start_on.val());
        };

        // 画面を離れる前の 価格表選択 終了処理
        window.g_prices.select_prices_fini = function() {
          // ブラウザ「戻る」→「進む」で選択肢が不当になる問題(turbolinksが画面更新時select2.jsの選択値を変更してしまう)対処
          // 保存しておく
          select_price_facility_save.val(select_price_facility.val());
          select_price_room_save.val(select_price_room.val());
          select_price_start_on_save.val(select_price_start_on.val());

          // 再度ブラウザの移動で戻ってきたときselect2が多重初期化される問題を回避
          // ブラウザ「戻る」「進む」等の画面遷移前にselect2を破棄しておく
          select_price_facility.select2('destroy');
          select_price_room.select2('destroy');
          select_price_start_on.select2('destroy');
        }
      }
    }

    window.g_prices.prices_by_date_type = function (prices, date_type) {
      let prices_dt = [];
      for (const price of prices) {
        if (price.date_type == date_type) {
          prices_dt.push(price);
        }
      }
      return prices_dt;
    };

    // 価格表 新規作成
    if (g_prices.is_prices_edit || g_prices.is_optional_prices_edit) {
      // 時間枠価格表 が編集中かどうか
      const prices_edit_is_editing = function () {
        return !$.isEmptyObject(input_values);
      }

      window.g_prices.confirm_editing = function () {
        let is_editing;
        switch (reservation_method) {
          case 'admin':
            is_editing = prices_edit_is_editing();
            break;
          case 'optional':
            // optional_price.jsに定義
            is_editing = g_prices.optional_prices_is_editing();
            break;
          default:
            is_editing = false;
        }

        if (is_editing) {
          return window.confirm('作成中の価格表を破棄します。よろしいですか？');
        }
        return true;
      };
    }

    // 時間枠価格表 新規作成
    if (g_prices.is_prices_edit) {
      const price_calendar_date_no = [1, 2, 3, 4, 5, 6, 7];
      const price_calendar_week = no => ('#price-calendar-week' + no);

      // 他ブロックから参照するのでvar宣言
      var input_values = {};

      const get_calendar_events_from_input_values = function (this_calendar) {
        const date_type = this_calendar.data('date-type');
        let events = [];
        $.each(input_values, function(k, v) {
          if (date_type !== v.date_type) { return true }
          events.push({
            id: k,
            date_type: v.date_type,
            title: '\\ ' + v.yen,
            yen: v.yen,
            start: v.checkin,
            end: v.checkout,
          })
        });
        return events;
      }

      // $('#price-calendar-weekday, #price-calendar-holiday').empty();
      $('#price-calendar-week1, #price-calendar-week2, #price-calendar-week3, #price-calendar-week4, #price-calendar-week5, #price-calendar-week6, #price-calendar-week7').empty();
      const params = calendar_base_params();
      Object.assign(params, {
        // Defines the buttons and title at the top of the calendar.
        header: {
          //left: 'today prev,next title',
          left: '',
          center: '',
          //right: 'month agendaWeek agendaDay'
          right: ''
        },
        // The initial view when the calendar loads.
        defaultView: 'agendaDay',
        minTime: '00:00:00',
        maxTime: '24:00:00',
        selectHelper: true,
        ignoreTimezone: false,

        // FIXME eventDrop, eventResize
        editable: false,
        // editable: true,

        droppable: false,
        displayEventEnd: true,
        //timeFormat: 'HH:mm',
        eventColor: 'rgba(150,150,150,0.2)',
        eventTextColor: "green",

        // Triggered when a date/time selection is made.
        select: function(start, end, jsEvent, view, resource) {
          createEvent(start, end, view);
        },
        eventDrop: function(event, delta, revertFunc, jsEvent, ui, view) {
          // FIXME TypeError: this.eventInstances[0] is undefined
          // May need to upgrade fullcalendar ver3.10.0 >> ver4.3.0~
          // Since there are many destructive changes, we will postpone it this time.
          // ref: https://github.com/fullcalendar/fullcalendar/issues/4127
          //      https://github.com/Apexal/late/issues/155

          // moveEvent(event, view);
        },
        eventResize: function(event, delta, revertFunc, jsEvent, ui, view) {
          // FIXME TypeError: this.eventInstances[0] is undefined
          // May need to upgrade fullcalendar ver3.10.0 >> ver4.3.0~
          // Since there are many destructive changes, we will postpone it this time.
          // ref: https://github.com/fullcalendar/fullcalendar/issues/4127
          //      https://github.com/Apexal/late/issues/155

          // moveEvent(event, view);
        },
        eventClick: function(calEvent, jsEvent, view) {
          // updateEvent(calEvent, view);
          removeEvent(calEvent, view);
        },
      });

      // カラムヘッダに空白をセットし、非表示化
      params.columnHeaderText = function(date) { return '' };
      $('#price-calendar-week1').fullCalendar(params);
      params.columnHeaderText = function(date) { return '' };
      $('#price-calendar-week2').fullCalendar(params);
      params.columnHeaderText = function(date) { return '' };
      $('#price-calendar-week3').fullCalendar(params);
      params.columnHeaderText = function(date) { return '' };
      $('#price-calendar-week4').fullCalendar(params);
      params.columnHeaderText = function(date) { return '' };
      $('#price-calendar-week5').fullCalendar(params);
      params.columnHeaderText = function(date) { return '' };
      $('#price-calendar-week6').fullCalendar(params);
      params.columnHeaderText = function(date) { return '' };
      $('#price-calendar-week7').fullCalendar(params);

      // polyfill
      const isInteger = function(val){
            return typeof value === "number" &&
                  isFinite(value) &&
                  Math.floor(value) === value;
      }

      const eventForm = function(start, end, yen, date_type, initial) {
        let price;
        if (!initial) {
          price = prompt('価格を入力して下さい: ', yen);

          let err_msg = '';
          if (price == null || price === '') {
            err_msg = '価格が入力されていません'
          } else if (!$.isNumeric(price)){
            err_msg = '数字を入力してください'
          } else if (isInteger(parseFloat(price))){
            err_msg = '整数で入力してください'
          } else if (parseInt(price) < 0){
            err_msg = 'マイナスの値は入力できません'
          }
          if (err_msg.length) {
            alert(err_msg);
            return null
          }
          price = parseInt(price);
        } else {
          price = yen;
        }
        var ret = {

          //title: '\\ ' + parseInt(price),

          checkin: start.format('HH:mm:ss'),
          checkout: end.format('HH:mm:ss'),
          yen: price,
          date_type: date_type,
        }
        const start_of_day = start.hour(0).minute(0).seconds(0)
        if (end - start_of_day >= 24 * 60 * 60 * 1000) {
          ret['checkout'] = '23:59:59'
        }
        return ret;
      };

      var delete_no_id_values = function () {
        // 曜日が指定されてない料金は削除
        var arrayData1 = $('input[name="ids[]"]');
        var arrayData2 = $('input[name="checkins[]"]');
        var arrayData3 = $('input[name="checkouts[]"]');
        var arrayData4 = $('input[name="yens[]"]');
        var arrayData5 = $('input[name="date_types[]"]');

        var deleteNo = [];
        $.each(input_values, function(k, v){
          let dataflg = 0;
          for (let i = 0; i < arrayData1.length; i++) {
            if (arrayData1[i].value == k)
            {
              dataflg++;
            }
          }
          if (dataflg == 0)
          {
            deleteNo.push(k);
          }
        });
        for (let i = 0; i < deleteNo.length; i++) {
          delete input_values[deleteNo[i]];
        }

        // 料金カレンダーの前詰め処理
        for (let i = 0; i < arrayData1.length; i++) {
          $.each(input_values, function(k, v){
            if (arrayData1[i].value == k && arrayData5[i].value != v.date_type) {          
              const datas = {
                checkin: arrayData2[i].value,
                checkout: arrayData3[i].value,
                yen: arrayData4[i].value,
                date_type: parseInt(arrayData5[i].value),
              }
              if (datas != null) {
                input_values[k] = datas
              }            
            }
          });
        }
      };

      var set_hidden_values = function() {
        $('.prices-hidden-field').empty();
        $.each(input_values, function(k, v){
          $('<input>').attr({ type: 'hidden', name: 'ids[]', value: k }).appendTo('.prices-hidden-field');
          $('<input>').attr({ type: 'hidden', name: 'checkins[]', value: v.checkin }).appendTo('.prices-hidden-field');
          $('<input>').attr({ type: 'hidden', name: 'checkouts[]', value: v.checkout }).appendTo('.prices-hidden-field');
          $('<input>').attr({ type: 'hidden', name: 'yens[]', value: v.yen }).appendTo('.prices-hidden-field');
          $('<input>').attr({ type: 'hidden', name: 'date_types[]', value: v.date_type }).appendTo('.prices-hidden-field');
        });
      };

      var set_calendar_value = function (this_calendar) {
        const events = get_calendar_events_from_input_values(this_calendar);
        this_calendar.fullCalendar('removeEvents');
        this_calendar.fullCalendar('addEventSource', events, true);
        this_calendar.fullCalendar('rerenderEvents');
      };

      // input_valuesに要素を追加するためのkeyを返す。
      // 1msec以内に複数回呼び出されても一意となるようにする。
      const get_key = function () {
        let now = Date.now();
        let key = now.toString(16);
        while (Object.hasOwn(input_values, key)) {
          now++;
          key = now.toString(16);
        }
        return key;
      };

      // 他ブロックから呼び出すのでvar宣言
      var createEvent_common = function (this_calendar, start, end, yen, initial) {
        const date_type = this_calendar.data('date-type');

        const data = eventForm(start, end, yen, date_type, initial);
        if (data == null) {
          return;
        }

        const temporary_key = get_key();
        input_values[temporary_key] = data;
      };

      const createEvent = function(start, end, view) {
        const this_calendar = $(view.el).parents('.price-calendar');
        delete_no_id_values();
        createEvent_common(this_calendar, start, end, 0, false);
        set_hidden_values();
        set_calendar_value(this_calendar);
        this_calendar.fullCalendar('unselect');
      }

      const updateEvent = function(event, view) {
        const this_calendar = $(view.el).parents('.price-calendar');
        const date_type = this_calendar.data('date-type');

        const data = eventForm(event.start, event.end, event.yen, date_type, false);
        if (data == null) {return false}

        input_values[event.id] = data;
        set_hidden_values();
        set_calendar_value(this_calendar);
        this_calendar.fullCalendar('unselect');
      };

      const moveEvent = function(event, view) {
        const this_calendar = $(view.el).parents('.price-calendar');

        input_values[event.id]['checkin'] = event.start.format('HH:mm:ss');
        input_values[event.id]['checkout'] = event.end.format('HH:mm:ss');
        set_hidden_values();
        set_calendar_value(this_calendar);
        this_calendar.fullCalendar('unselect');
      };

      var removeEvent = function(event, view) {
        const this_calendar = $(view.el).parents('.price-calendar');
        var res = confirm("本当に削除しますか？");
        if (res === false) {return false}

        delete input_values[event.id];

        var arrayDate1 = $('input[name="ids[]"]');
        var arrayDate2 = $('input[name="checkins[]"]');
        var arrayDate3 = $('input[name="checkouts[]"]');
        var arrayDate4 = $('input[name="yens[]"]');
        var arrayDate5 = $('input[name="date_types[]"]');
        for (let i = 0; i < arrayDate1.length; i++) {
          $.each(input_values, function(k, v){
            if (arrayDate1[i].value == k && arrayDate5[i].value != v.date_type) {          
              const datas = {
                checkin: arrayDate2[i].value,
                checkout: arrayDate3[i].value,
                yen: arrayDate4[i].value,
                date_type: parseInt(arrayDate5[i].value),
              }
              if (datas != null) {
                input_values[k] = datas
              }
              
            }
          });
        }
        
        set_hidden_values();
        set_calendar_value(this_calendar);
        this_calendar.fullCalendar('unselect');
      };

      const el1 = document.getElementsByClassName("week1");
      const el2 = document.getElementsByClassName("week2");
      const el3 = document.getElementsByClassName("week3");
      const el4 = document.getElementsByClassName("week4");
      const el5 = document.getElementsByClassName("week5");
      const el6 = document.getElementsByClassName("week6");
      const el7 = document.getElementsByClassName("week7");
  
      // チェックボックスの付け替え
      $('input#dayname1').change(function () {
        for (let i = 0; i < el1.length; i++) {
          var prop = $(el1[i]).prop('checked');
          if (prop) {
            for (let step = 0; step < 7; step++) {
              this_el = [el1,el2,el3,el4,el5,el6,el7][step]
              if (step != 0) {
                $(this_el[i]).prop('checked', false);
              }
            }
          }
        }   
      })
  
      // チェックボックスの付け替え
      $('input#dayname2').change(function () {
        for (let i = 0; i < el2.length; i++) {
          var prop = $(el2[i]).prop('checked');
          if (prop) {
            for (let step = 0; step < 7; step++) {
              this_el = [el1,el2,el3,el4,el5,el6,el7][step]
              if (step != 1) {
                $(this_el[i]).prop('checked', false);
              }
            }
          }
        }   
      })
  
      // チェックボックスの付け替え
      $('input#dayname3').change(function () {
        for (let i = 0; i < el3.length; i++) {
          var prop = $(el3[i]).prop('checked');
          if (prop) {
            for (let step = 0; step < 7; step++) {
              this_el = [el1,el2,el3,el4,el5,el6,el7][step]
              if (step != 2) {
                $(this_el[i]).prop('checked', false);
              }
            }
          }
        }   
      })
  
      // チェックボックスの付け替え
      $('input#dayname4').change(function () {
        for (let i = 0; i < el4.length; i++) {
          var prop = $(el4[i]).prop('checked');
          if (prop) {
            for (let step = 0; step < 7; step++) {
              this_el = [el1,el2,el3,el4,el5,el6,el7][step]
              if (step != 3) {
                $(this_el[i]).prop('checked', false);
              }
            }
          }
        }   
      })
  
      // チェックボックスの付け替え
      $('input#dayname5').change(function () {
        for (let i = 0; i < el5.length; i++) {
          var prop = $(el5[i]).prop('checked');
          if (prop) {
            for (let step = 0; step < 7; step++) {
              this_el = [el1,el2,el3,el4,el5,el6,el7][step]
              if (step != 4) {
                $(this_el[i]).prop('checked', false);
              }
            }
          }
        }   
      })
  
      // チェックボックスの付け替え
      $('input#dayname6').change(function () {
        for (let i = 0; i < el6.length; i++) {
          var prop = $(el6[i]).prop('checked');
          if (prop) {
            for (let step = 0; step < 7; step++) {
              this_el = [el1,el2,el3,el4,el5,el6,el7][step]
              if (step != 5) {
                $(this_el[i]).prop('checked', false);
              }
            }
          }
        }   
      })
  
      // チェックボックスの付け替え
      $('input#dayname7').change(function () {
        for (let i = 0; i < el7.length; i++) {
          var prop = $(el7[i]).prop('checked');
          if (prop) {
            for (let step = 0; step < 7; step++) {
              this_el = [el1,el2,el3,el4,el5,el6,el7][step]
              if (step != 6) {
                $(this_el[i]).prop('checked', false);
              }
            }
          }
        }   
      })
  
      // カウントボタンを取得する
      const calendaradd = document.getElementById("calendar_add");
  
      // チェック済みのチェックボックスの数を返す
      const getCheckedCount = () => {
        // 可視セルの件数カウント
        let count = 0;				// チェックボックスのチェック状態の件数
        let tableCnt = 0;			// 可視化されているCell件数
        let noChkCnt = 0;			// 可視化状態Cellで全てのチャックボックスが未チェック状態の件数
  
        for (let step = 0; step < 7; step++) {
          this_el = [el1,el2,el3,el4,el5,el6,el7][step]
          this_td = [$('#td1'),$('#td2'),$('#td3'),$('#td4'),$('#td5'),$('#td6'),$('#td7')][step]
  
          if (this_td.css('display') != 'none') {
            let chkCnt = 0;
            for (let i = 0; i < this_el.length; i++) {
              if (this_el[i].checked) {
                count++;
                chkCnt++
              }
            }
            if (chkCnt == 0) {
              noChkCnt++;
            }
            tableCnt++;
          }
        }
  
        if (7 - (count + noChkCnt) > 0)
        {
          // CSS更新
          let setWidth = (tableCnt + 1) * 400;
          $('#calen_table').css({ 'width': setWidth + 'px' });
        
          for (let step = 0; step < 7; step++) {
            this_td = [$('#td1'),$('#td2'),$('#td3'),$('#td4'),$('#td5'),$('#td6'),$('#td7')][step]
            this_tdsp = [$('#td1sp'),$('#td2sp'),$('#td3sp'),$('#td4sp'),$('#td5sp'),$('#td6sp'),$('#td7sp')][step]
            this_table = [$('#price-calendar-week1'),$('#price-calendar-week2'),$('#price-calendar-week3'),$('#price-calendar-week4'),$('#price-calendar-week5'),$('#price-calendar-week6'),$('#price-calendar-week7')][step]
  
            if (this_td.css('display') == 'none') {
              // 非表示の場合
              this_td.css('display', 'table-cell');
              this_tdsp.css('display', 'table-cell', { 'width': '10px' });
              this_table.fullCalendar('removeEvents');
              this_table.fullCalendar('rerenderEvents');
              return null;
            }
          } 
        }
      };
  
      if (calendaradd != null)
      {
        //ボタンをクリックした時に「getCheckedCount()」を呼び出す
        calendaradd.addEventListener("click", getCheckedCount, false);
      }
  
      // クリアボタンを取得する
      const calendarclear = document.getElementById("calendar_clear");
  
      // 全ての曜日チェックボックスが未チェックの場合は不可視
      const getCalenCheckedCount = () => {
        let tableCnt = 7;
  
        // 曜日チェックボックスのチェック状態確認
        for (let step = 0; step < 7; step++) {
          let chkCnt = 0;
          this_el = [el1,el2,el3,el4,el5,el6,el7][step];
          this_td = [$('#td1'),$('#td2'),$('#td3'),$('#td4'),$('#td5'),$('#td6'),$('#td7')][step];
          this_tdsp = [$('#td1sp'),$('#td2sp'),$('#td3sp'),$('#td4sp'),$('#td5sp'),$('#td6sp'),$('#td7sp')][step];
          const this_calendar = $(price_calendar_week(price_calendar_date_no[step]));
          for (let i = 0; i < this_el.length; i++) {
            if (this_el[i].checked) {
              chkCnt++;
            }
          }
  
          if (chkCnt == 0) {
            let chkCnt2 = 0;
            let moveFlg = 0;
            for (let step2 = step + 1; step2 < 7; step2++) {
              this_el2 = [el1,el2,el3,el4,el5,el6,el7][step2];
              for (let j = 0; j < this_el2.length; j++) {
                if (this_el2[j].checked) {
                  chkCnt2++;
                }
              }
  
              if (chkCnt2 > 0 && moveFlg == 0) {
                const this_calendar2 = $(price_calendar_week(price_calendar_date_no[step2]));
                moveFlg = 1;
  
                for (let k = 0; k < this_el2.length; k++) {
                  if (this_el2[k].checked) {
                    $(this_el[k]).prop('checked', true);
                    $(this_el2[k]).prop('checked', false);
                  }
                }
  
                const events = this_calendar.fullCalendar('clientEvents');
                for (const index in events) {
                  delete input_values[events[index].id];
                }
                this_calendar.fullCalendar('removeEvents');
  
                const date_type = this_calendar.data('date-type');
                let events2 = this_calendar2.fullCalendar('clientEvents');
                this_calendar2.fullCalendar('removeEvents');
  
                for (i in events2) {
                  const id = events2[i].id;
                  // this_calendarへ移動
                  input_values[id].date_type = date_type;
                  events2[i].date_type = date_type;
                }
  
                this_calendar.fullCalendar('addEventSource', events2, true);
                this_calendar.fullCalendar('rerenderEvents');
  
                set_hidden_values();
              }
            }
            
            if (moveFlg == 0)
            {
              this_td.css('display', 'none');
              this_tdsp.css('display', 'none');
              var array = this_calendar.fullCalendar('clientEvents');
              for (const index in array) {
                delete input_values[array[index].id];
              }
              this_calendar.fullCalendar('removeEvents');
              tableCnt--;

              set_hidden_values();
            }
          }
        }
  
        // 価格表が全て削除された場合は、初期化された価格表を1件表示
        if (tableCnt == 0) {
          $('#td1').css('display', 'table-cell');
          $('#td1sp').css('display', 'table-cell', { 'width': '10px' });
          $('#price-calendar-week1').fullCalendar('removeEvents');
          $('#price-calendar-week1').fullCalendar('rerenderEvents');
  
          for (let i = 0; i < el1.length; i++) {
            $(el1[i]).prop('checked', true);
          }
  
          tableCnt=1;
        }
  
        // CSS更新
        let setWidth = tableCnt * 400;
        $('#calen_table').css({ 'width': setWidth + 'px' });
      };
  
      if (calendarclear != null)
      {
        // ボタンをクリックした時に「getCheckedCount()」を呼び出す
        calendarclear.addEventListener("click", getCalenCheckedCount, false);
      }
  
      // 【先着予約受付－保存する】曜日チェックボックスのチェック状態確認
      $('#calendar-save').on('click', function() {
        for (let step = 0; step < 7; step++) {
          this_calendar = [$('#price-calendar-week1'),$('#price-calendar-week2'),$('#price-calendar-week3'),$('#price-calendar-week4'),$('#price-calendar-week5'),$('#price-calendar-week6'),$('#price-calendar-week7')][step]
          var array = this_calendar.fullCalendar('clientEvents');
          
          // 曜日チェックボックスのチェック数カウント
          this_el = [el1, el2, el3, el4, el5, el6, el7][step]
          let chkCnt = 0;
          for (let i = 0; i < this_el.length; i++) {
            if (this_el[i].checked) {
              chkCnt++;
            }
          }
  
          if (array.length > 0) {
            // 料金が入力されていて曜日がチェックされていない項目の確認
            if (chkCnt == 0) {
                alert("曜日が指定されていない項目が存在します。");
                return false;
            }
          }
          else
          {
            // 曜日がチェックされていて料金が入力されていない項目の確認
            if (chkCnt > 0) {
              alert("料金(円) が入力されていません。");
              return false;
            }        
          }
        }    
      });
    
      // 既存の価格表から作成する時はpricesが設定されている。表示の時はprices == null
      if (prices != null) {
        const sort_compare_asc = (a, b) => ((a < b) ? -1 : 1);

        const sort_prices_by_time = function (prices) {
          prices.sort((a, b) => sort_compare_asc(a.checkout, b.checkout));
          prices.sort((a, b) => sort_compare_asc(a.checkin, b.checkin));
          return prices;
        }

        // 時間枠価格表 単一枠が同一かどうか

        const same_price = function (p1, p2) {
          if ((p1.checkin == p2.checkin) &&
            (p1.checkout == p2.checkout) &&
            (p1.yen == p2.yen)) {
            return true;
          }
          return false;
        }

        // 時間枠価格表 複数枠が同一かどうか

        const same_prices = function (pa1, pa2) {
          if (pa1.length != pa2.length) {
            return false;
          }
          for (let i = 0; i < pa1.length; i++) {
            if (!same_price(pa1[i], pa2[i])) {
              return false;
            }
          }
          return true;
        }

        // 時間枠価格表の同じ枠の曜日をマージ

        const merge_prices = function (prices) {
          let prices_array = [];
          for (date_type of g_prices.date_types) {
            const prices_by_date_type = g_prices.prices_by_date_type(prices, date_type);
            if (prices_by_date_type.length > 0) {
              prices_array.push(sort_prices_by_time(prices_by_date_type));
            }
          };

          // merged_prices: {date_types: [date_type1, date_type2, ...], prices: prices}
          let merged_prices = [];
          let prices_a;
          while (prices_a = prices_array.shift()) {
            const prices_a_date_type = prices_a[0].date_type;
            let merged_price_a = {date_types: [prices_a_date_type], prices: prices_a};
            let prices_b_array = Array.from(prices_array);
            let prices_b;
            while (prices_b = prices_b_array.shift()) {
              const prices_b_date_type = prices_b[0].date_type;
              if (same_prices(prices_a, prices_b)) {
                merged_price_a.date_types.push(prices_b_date_type);
                const index_b = prices_array.indexOf(prices_b);
                prices_array.splice(index_b, 1);
              }
            }
            merged_prices.push(merged_price_a);
          }
          return merged_prices;
        }

        // 時間枠価格表 新規作成 初期化

        // カレンダーイベント

        const create_fullcalendar_events = function (merged_prices) {
          for (let i = 0; i < merged_prices.length; i++) {
            const this_calendar = $(price_calendar_week(price_calendar_date_no[i]));
            merged_prices[i].prices.forEach(price => {
              createEvent_common(
                this_calendar,
                $.fullCalendar.moment(price.checkin),
                $.fullCalendar.moment(price.checkout),
                price.yen,
                true
              );
            });
            set_calendar_value(this_calendar);
          }
          set_hidden_values();
        }

        const price_calendar_td = no => $('#td' + no);
        const price_calendar_tdsp = no => $('#td' + no + 'sp');

        // 曜日チェックボックス

        const render_checkboxes = function (no, date_types) {
          g_prices.date_types.forEach(date_type => {
            let checkbox = price_calendar_td(no).find('.week' + no + '[name="' + date_type + no + '"]');
            if (date_types.includes(date_type)) {
              checkbox.prop('checked', true);
            } else {
              checkbox.prop('checked', false);
            }
          });
        }

        // カレンダー

        const render_calendar = function (no) {
          let td = price_calendar_td(no);
          let tdsp = price_calendar_tdsp(no);
          if (td.css('display') == 'none') {
            td.css('display', 'table-cell');
            tdsp.css('display', 'table-cell', { 'width': '10px' });
            $(price_calendar_week(no)).fullCalendar('rerenderEvents');
          }
        }

        const render_calendars = function (merged_prices) {
          $('#calen_table').css({ 'width': (400 * merged_prices.length) + 'px' });
          for (let i = 0; i < merged_prices.length; i++) {
            const no = price_calendar_date_no[i];
            const date_types = merged_prices[i].date_types;
            render_checkboxes(no, date_types);
            render_calendar(no);
          }
        }

        // 時間枠価格表 新規作成 初期化

        // prices: {checkin: Todayのcheckin時分秒, checkout: Todayのcheckout時分秒, date_type: 曜日, yen: 料金}の配列
        const merged_prices = merge_prices(prices);
        // merged_prices: {date_types: [date_type1, date_type2, ...], prices: prices(date_typeは使用しない)}の配列
        create_fullcalendar_events(merged_prices);
        render_calendars(merged_prices);
      } // prices != null

      // 「既存の価格表から作成」ボタン
      $('#select_prices').on('click', function () {
        return g_prices.confirm_editing();
      });
    }
  }
});
