$(document).on('ready turbolinks:load', function() {
  $('[data-mail-template-keyword-to-clipboard]').on('click', mail_template_keyword_to_clipboard)
});

function mail_template_keyword_to_clipboard(evt) {
  const keyword = $(evt.target).text().trim()

  //keywordを含んだtextareaをbodyタグの末尾に設置
  $(document.body).append("<textarea id=\"tmp_copy\" style=\"position:fixed;right:100vw;font-size:16px;\" readonly=\"readonly\">" + keyword + "</textarea>");

  //elmはtextareaノード
  var elm = $("#tmp_copy")[0];

  //select()でtextarea内の文字を選択
  elm.select();

  //rangeでtextarea内の文字を選択
  var range = document.createRange();
  range.selectNodeContents(elm);
  var sel = window.getSelection();
  sel.removeAllRanges();
  sel.addRange(range);
  elm.setSelectionRange(0, 999999);

  //execCommandを実施
  document.execCommand("copy");

  //textareaを削除
  $(elm).remove();
}
