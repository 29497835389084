$(document).on('turbolinks:load', function() {
  window.g_reservation_slot = {
    is_search_form: ($('#reservation_search_form').length > 0),
    is_export_form: ($('#reservation_export_form').length > 0),
    is_export_reservable_form: ($('#reservable_slot_form').length > 0),

    search_form_fini: null,
  };

  const is_search_form = g_reservation_slot.is_search_form;
  const is_export_form = g_reservation_slot.is_export_form;
  const is_export_reservable_form = g_reservation_slot.is_export_reservable_form;

  if (is_search_form || is_export_form || is_export_reservable_form) {
    class ReservationForm {
      constructor(model_name) {
        // form
        const form_root_sel = `#${model_name}_form`;
        // formの子要素CSSセレクタの文字列フォーマット
        const disp_block_sel = attr => `#${model_name}_${attr}_disp_block`;
        const control_sel = attr => `[name="${model_name}[${attr}]"]`;
        const control_arr_sel = attr => `[name="${model_name}[${attr}][]"]`;
        const all_sel = attr => `#${model_name}_${attr}_all`;
        const submit_sel = `#${model_name}_submit`;
        const form_el = sel => $(`${form_root_sel} ${sel}`);

        this.disp_block_el = attr => form_el(disp_block_sel(attr));
        this.control_el = attr => form_el(control_sel(attr));
        this.control_arr_el = attr => form_el(control_arr_sel(attr));
        this.all_el = attr => form_el(all_sel(attr));
        this.submit_el = form_el(submit_sel);
      }

      set_select_options(select_el, opts, default_value) {
        select_el.empty();
        for (const opt of opts) {
          const newOption = new Option(opt[0], opt[1], false, false);
          select_el.append(newOption);
        }
        if (default_value != undefined) {
          select_el.val(default_value);
        }
      };

      all_checked(els, all_val) {
        return els.map(function (index, e, val) {
          return $(this).prop('checked');
        }).get().every(val => val == all_val);
      }

      init_checkbox_all(attrs) {
        attrs.forEach(attr => {
          // 「全て」が変更されたら、その属性の全項目のチェック状態を「全て」に合わせる
          this.all_el(attr).on('change', () => {
            this.control_arr_el(attr).prop('checked', this.all_el(attr).prop('checked'));
          });

          this.control_arr_el(attr).on('change', () => {
            // 属性の全項目のチェック状態がチェックになったら「全て」をチェック。そうでなければチェックオフ。
            if (this.all_checked(this.control_arr_el(attr), true)) {
              this.all_el(attr).prop('checked', true);
            } else {
              this.all_el(attr).prop('checked', false);
            }
          });
          this.control_arr_el(attr).trigger('change');
        });
      }
    };

    if (is_search_form) {

      // 予約 詳細検索条件指定画面

      const form = new ReservationForm('reservation_search');

      // select2要素
      const select2_controls = {
        // "モデルの属性": "ajaxパラメータ(https://select2.org/data-sources/ajax)"
        user_id: { url: '/user_resources/search' },
        facility: { url: '/facilities/search' },
        room_id: {
          url: '/rooms/search',
          data: function (params) {
            const facility_id = form.control_el('facility').val();
            const room_name = params.term;
            let query = {};
            Object.assign(query, params);
            query.q = room_name;
            if (facility_id != 'all') {
              query.facility_id = facility_id;
            }
            return query;
          }
        }
      };

      const select2_arg = attr => {
        const ajax_arg = select2_controls[attr];
        let ajax = {
          url: ajax_arg.url,
          dataType: 'json'
        };
        if (ajax_arg.data) {
          // select2 request parameter override
          ajax.data = ajax_arg.data;
        }
        let arg = { ajax: ajax };
        return arg;
      };

      // 予約 詳細検索条件指定画面 初期化処理

      const search_form_init = function () {
        // select2要素
        for (const attr in select2_controls) {
          form.control_el(attr).select2(select2_arg(attr));
        }

        // 期間
        form.control_el('date_range').on('change', function() {
          // 未指定の時は開始日/終了日をdisabled
          [ 'start_on', 'end_on' ].forEach(function (attr) {
            if (form.control_el('date_range').val() == 'none') {
              form.control_el(attr).prop('disabled', true);
            } else {
              form.control_el(attr).prop('disabled', false);
            }
          });
        });
        form.control_el('date_range').trigger('change');

        // 利用者(種別)
        form.control_el('user_kind').on('change', function() {
          // 選択された場合表示
          [ 'user', 'user_id', 'user_type' ].forEach(function (attr) {
            if (form.control_el('user_kind').val() == attr) {
              form.disp_block_el(attr).show();
            } else {
              form.disp_block_el(attr).hide();
            }
          });
        });
        form.control_el('user_kind').trigger('change');

        // 施設
        let prev_facility_id = form.control_el('facility').val();
        form.control_el('facility').on('change.select2', function () {
          const facility_id = form.control_el('facility').val();
          const requested_setting_id = (facility_id != prev_facility_id) ? 'all' : form.control_el('requested_setting').val();

          // 部屋選択肢(ajax)
          form.control_el('room_id').select2('data');
          if (facility_id != prev_facility_id) {
            form.control_el('room_id').val('all');
            form.control_el('room_id').trigger('change');
          }
          prev_facility_id = facility_id;

          // 申請内容選択肢
          form.set_select_options(form.control_el('requested_setting'), all_facility_requested_settings[facility_id], requested_setting_id);
          form.control_el('requested_setting').trigger('change');

          // 施設：全て 「施設・部屋名」を表示
          const room = 'room'
          if (facility_id == 'all') {
            form.disp_block_el(room).show();
          } else {
            form.disp_block_el(room).hide();
          }

          // どれかの施設 「部屋」「申請内容」を表示
          [ 'room_id', 'requested_setting' ].forEach(function (attr) {
            if (facility_id == 'all') {
              form.disp_block_el(attr).hide();
            } else {
              form.disp_block_el(attr).show();
            }
          });
        });
        form.control_el('facility').trigger('change.select2');

        // 「全て」チェックボックス
        const checkbox_all_attrs = [ 'status', 'payment_status', 'payment_method' ];
        form.init_checkbox_all(checkbox_all_attrs);

        form.submit_el.on('click', function () {
          const start_on = form.control_el('start_on').val();
          const end_on = form.control_el('end_on').val();
          if ((start_on != '') && (end_on != '')) {
            if (start_on > end_on) {
              alert('開始日<=終了日にしてください');
              return false;
            }
            return true;
          }
        });
        form.submit_el.trigger('submit');
      };

      const search_form_fini = function () {
          for (const attr in select2_controls) {
            form.control_el(attr).select2('destroy');
          }
      };

      window.g_reservation_slot.search_form_fini = search_form_fini;

      // 詳細検索条件 画面初期化
      search_form_init();
    }

    if (is_export_form) {

      // 予約情報CSV出力 画面初期化処理

      const export_form_init = function () {
        const form = new ReservationForm('reservation_export');

        const checkbox_all_attrs = [ 'column' ];  
        form.init_checkbox_all(checkbox_all_attrs);
      };

      // 予約情報CSV出力 画面初期化
      export_form_init();
    }

    if (is_export_reservable_form) {
      const form = new ReservationForm('reservable_slot');
      const start_on_el = form.control_el('start_on');
      const end_on_el = form.control_el('end_on');

      const is_background = function (days) {
        // nroom: 部屋数(edit_reservable_slot.htmlにて初期設定されているグローバル変数)
        return ((days * nroom) > reservable_slot_background_threshold);
      };

      const switch_export_background = function() {
        const start_on = start_on_el.val();
        const end_on = end_on_el.val();
        if ((start_on == '') || (end_on == '')) {
          return;
        }
        start_time = Date.parse(start_on);
        end_time = Date.parse(end_on);
        if (start_time > end_time) {
          return;
        }
        const days = ((end_time - start_time) / (24 * 60 * 60 * 1000)) + 1; // start_onとend_onを含む日数
        if (is_background(days)) {
          form.disp_block_el('background').show();
          form.disp_block_el('foreground').hide();
        } else {
          form.disp_block_el('background').hide();
          form.disp_block_el('foreground').show();
        }
      };

      // 予約枠情報CSV出力 画面初期化処理

      const export_reservable_form_init = function () {
        form.submit_el.on('click', function () {
          const start_on = start_on_el.val();
          const end_on = end_on_el.val();
          if (start_on == '') {
            alert('開始日を入力してください');
            return false;
          }
          if (end_on == '') {
            alert('終了日を入力してください');
            return false;
          }
          if (start_on > end_on) {
            alert('開始日<=終了日にしてください');
            return false;
          }
          return true;
        });
        form.submit_el.trigger('submit');

        start_on_el.on('change', function() {
          switch_export_background();
        });

        end_on_el.on('change', function() {
          switch_export_background();
        });

        end_on_el.trigger('change');
      };

      // CSRF token error occurs when using turbolinks with ruby on rails
      //   https://stackoverflow.com/questions/55040734/csrf-token-error-occurs-when-using-turbolinks-with-ruby-on-rails
      $.rails.refreshCSRFTokens();

      // 予約情報CSV出力 画面初期化
      export_reservable_form_init();
    }
  }
});

$(document).on('turbolinks:before-cache', function() {
  if (g_reservation_slot.is_search_form) {
    g_reservation_slot.search_form_fini();
  }
});
