$(document).on('turbolinks:load', function() {
  $('li.user-item').hover(
    function() {
      $(this).find('.dropdown-menu').delay(200).show();
      return;
    },
    function() {
      $(this).find('.dropdown-menu').delay(200).hide();
      return;
    }
  )
});
