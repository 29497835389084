$(document).on('turbolinks:load', function() {
  var events_url = location.pathname.replace(/\/[^\/]+$/, '/closed_days')

  $('#closed_day_calendar').empty()

  const params = calendar_base_params()

  Object.assign(params, {
    // Defines the buttons and title at the top of the calendar.
    header: {
      left: 'today',
      center: 'prev title next',
      right: '',
      //right: 'month agendaWeek agendaDay'
    },

    // The initial view when the calendar loads.
    defaultView: 'month',

    // A URL of a JSON feed that the calendar will fetch Event Objects from.
    //events (as a json feed)
    events: events_url, // イベント（チップ）の表示

    
    // 日付クリック時の処理
    // サーバに対して休館日イベント追加依頼をして、成功時にはイベントを描画する。
    /*
    dayClick: function(day, jsEvent, view) {
      $.ajax({
        type: 'POST',
        url: events_url,
        data: { closed_day: { date: day.format() } },
        dataType: 'json'
      }).then(
        function(data) {
          console.log('OK: create event:', data)
          // TODO: 追加後に削除できない
          //$('#closed_day_calendar').fullCalendar('renderEvent', data, true)
          $('#closed_day_calendar').fullCalendar('refetchEvents')
        },
        function() {
          console.log('NG: create event')
        }
      )
      return false
    },
    */
    // 日付枠のクリックおよびドラッグ
    select: function(start, end, jsEvent, view) {
      var start_on = start.format() // 選択した日付の開始日
      var end_on = end.subtract(1, 'd').format()  // 選択した日付の終了日
      if (start_on == end_on) {
        // 1日
        closed_day = {
          date: start_on,
        }
      } else {
        // 連続した日
        closed_day = {
          start_on: start_on,
          end_on: end_on,
          every: 'daily'
        }
      }
      $.ajax({
        type: 'POST',
        url: events_url,
        data: {
          closed_day: closed_day
        },
        dataType: 'json'
      }).then(
        function(data) {
          console.log('OK: create event:', data)
          $('#closed_day_calendar').fullCalendar('refetchEvents')
        },
        function(data) {
          if (data.status == 200) {
            eval(data.responseText)
            console.log('Confirm: create event')
          } else{
            console.log('NG: create event')
          }
        }
      )
      return false
    },
    /*unselect: function(jsEvent, view) {
      console.log("===== unselect =====")
      console.log(jsEvent)
      console.log(view)
    },*/

    // 休館日イベントクリック時の処理
    // サーバに対して当該休館日イベント削除依頼をして、成功時には描画からも削除する。
    eventClick: function(calEvent, jsEvent, view) {
      if(calEvent.type == 'upper'){
        alert(calEvent.name + 'の休館日は' + calEvent.name + 'のカレンダーで削除してください');
        return false;
      }
      $.ajax({
        type: 'DELETE',
        url: calEvent.url,
      }).then(
        function(data, textStatus, jqXHR) {
          console.log('OK: delete event (id=' + calEvent._id + ')')
          if(jqXHR.status == 204){
            $('#closed_day_calendar').fullCalendar('refetchEvents')
          }
          else if(jqXHR.status == 200 && data.data == 'confirm'){
            // ルール削除時は確認
            if(confirm("同じ適用日の休館日が一斉に削除されますがよろしいでしょうか？") === true){
              $.ajax({
                type: 'DELETE',
                url: calEvent.url + '?force=1',
              }).then(function(){
                // ルール適用可能日が変わるのでリロード
                location.reload();
              }, function(){
                console.log('NG: delete event (id=' + calEvent._id + ')')
              });
            }
            return;
          }
        },
        function() {
          console.log('NG: delete event (id=' + calEvent._id + ')')
        }
      )
      return false
    },

    editable: false,
    droppable: false,
    //displayEventEnd: true,
    //timeFormat: 'HH:mm',

    eventColor: 'rgba(150,150,150,0.2)',
    disableDragging: true,
  })

  // 休館打ち消し確認モーダル(3択)のフォーム送信時にモーダルを閉じる。
  $('#closed_day_confirm_neutralize_modal_form').on('submit', function(event) {
      $('#closed_day_confirm_neutralize_modal').modal('hide');
  });
  
  // 休館打ち消し確認モーダル(3択)のフォームのAjax通信成功時にカレンダーを再読み込み。
  $('#closed_day_confirm_neutralize_modal_form').on('ajax:success', function(event) {
    $('#closed_day_calendar').fullCalendar('refetchEvents')
  });

  // 休館登録確認モーダルのフォーム送信時にモーダルを閉じる。
  $('#closed_day_confirm_close_modal_form').on('submit', function(event) {
      $('#closed_day_confirm_close_modal').modal('hide');
  });

  // 休館登録確認モーダルのフォームのAjax通信成功時にカレンダーを再読み込み。
  $('#closed_day_confirm_close_modal_form').on('ajax:success', function(event) {
    $('#closed_day_calendar').fullCalendar('refetchEvents')
  });

  // 複数休館登録確認モーダルのフォーム送信時にモーダルを閉じる。
  $('#closed_day_confirm_close_daily_modal_form').on('submit', function(event) {
    $('#closed_day_confirm_close_daily_modal').modal('hide');
  });

  // 複数休館登録確認モーダルのフォームのAjax通信成功時にカレンダーを再読み込み。
  $('#closed_day_confirm_close_daily_modal_form').on('ajax:success', function(event) {
    $('#closed_day_calendar').fullCalendar('refetchEvents')
  });

  // IE だと formのremoteがきかない？
  $('[data-ajax-modal]').on('click', function(){
    const $this = $(this);
    const $form = $(this).closest('form')
    const data = $form.serializeArray();
    if (($('#raffle_application_starts_at_modal').length > 0) && ($('#raffle_application_ends_at_modal').length > 0)) {
      if ($('#raffle_application_starts_at_modal').val() >= $('#raffle_application_ends_at_modal').val()) {
        alert('利用終了は、利用開始より後の時間を指定してください。');
        return false;
      }
    }
    if ($this.attr('name')){
      data.push({'name': $this.attr('name'), 'value': $this.val()});
    }
    $.ajax({url: $form.attr('action'), type: 'POST', data: data}).then(function(){
      $('#closed_day_calendar').fullCalendar('refetchEvents');
    });
    $form.closest('.modal').modal('hide');
    return false
  })

  $('#closed_day_calendar').fullCalendar(params)
})

