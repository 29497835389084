window.calendar_base_params = function() {
  return {
    //===== Toolbar =====

    // Defines the buttons and title at the top of the calendar.
    header: {
      left: 'today',
      center: 'prev title next',
      right: 'month agendaWeek agendaDay'
    },

    // Defines the controls at the bottom of the calendar.
    //footer:

    views: {
      month: {
        titleFormat: 'YYYY年M月'
      },
      week: {
        titleFormat: 'YYYY年M月D日',
        titleRangeSeparator: '日 ～ ',
        columnFormat: 'M/D（ddd）'
      },
      day: {
        titleFormat: 'YYYY年M月D日（ddd）'
      }
    },

    // Determines the separator text when formatting the date range in the toolbar title.
    //titleRangeSeparator:

    // Text that will be displayed on buttons of the header/footer.
    buttonText: {
      prev: ' < ',
      next: ' > ',
      prevYear: ' << ',
      nextYear: ' >> ',
      today: '今日',
      month: '月表示',
      week: '週表示',
      day: '日表示'
    },

    // Icons that will be displayed in buttons of the header/footer.
    //buttonIcons:

    // Defines custom buttons that can be used in the header/footer.
    //customButtons:

    //===== Theme =====

    //===== Sizing =====

    // Sets the height of the entire calendar, including header and footer.
    //height:

    // Sets the height of the view area of the calendar.
    contentHeight: 'auto',

    // Sets the width-to-height aspect ratio of the calendar.
    //aspectRatio:

    // Immediately forces the calendar to readjusts its size.
    //updateSize:

    // Whether to automatically resize the calendar when the browser window resizes.
    //handleWindowResize:

    // The time the calendar will wait to adjust its size after a window resize occurs, in milliseconds.
    //windowResizeDelay:

    //----- CALLBACKS -----
    // Triggered after the calendar’s dimensions have been changed due to the browser window being resized.
    //windowResize:

    //===== MONTH-VIEW-SPECIFIC OPTIONS =====

    // Determines the number of weeks displayed in a month view.
    //fixedWeekCount:
    fixedWeekCount: false,

    // In month view, whether dates in the previous or next month should be rendered at all.
    //showNonCurrentDates:
    showNonCurrentDates: true,

    //===== TIMEGRID-SPECIFIC OPTIONS =====

    // Determines if the “all-day” slot is displayed at the top of the calendar.
    allDaySlot: true,
    //allDaySlot: 'all-day',

    // The text titling the “all-day” slot at the top of the calendar.
    allDayText: '時刻',

    // Determines if timed events in TimeGrid view should visually overlap.
    slotEventOverlap: false,
    //slotEventOverlap: true,

    // Guarantees that events within the TimeGrid views will be a minimum height.
    timeGridEventMinHeight: 100,

    //===== LIST-VIEW-SPECIFIC OPTIONS =====

    // A Date Formatter that affects the text on the left side of the day headings in list view.
    //listDayFormat:

    // A Date Formatter that affects the text on the right side of the day headings in list view.
    //listDayAltFormat:

    // The text that is displayed in the middle of list view, alerting the user that there are no events within the given range.
    //noEventsMessage:

    //===== CUSTOM-VIEW =====
    // Sets the exact duration of a custom view.
    //duration:

    // Sets the exact number of days displayed in a custom view, regardless of weekends or hiddenDays.
    //dayCount:

    // Sets the exact date range that is visible in a view.
    //visibleRange: {
    //  start: '2019-10-25',
    //  end: '2019-11-01'
    //},

    //===== VIEW-API =====
    // The initial view when the calendar loads.
    defaultView: 'month',

    //----- CALLBACKS -----

    // Triggered after a view’s non-date-related DOM structure has been rendered.
    //viewSkeletonRender:

    // Triggered before a view’s DOM skeleton is removed from the DOM.
    //viewSkeletonDestroy:

    // Triggered when a new set of dates has been rendered.
    //datesRender:

    // Triggered before the currently rendered set of dates is removed from the DOM.
    //datesDestroy:

    //===== Date & Time Display =====

    //----- WHOLE-DAY SETTINGS -----
    // Whether to include Saturday/Sunday columns in any of the calendar views.
    weekends: true,

    // Exclude certain days-of-the-week from being displayed.
    //hiddenDays:

    // Whether the day headers should appear. For the Month, TimeGrid, and DayGrid views.
    //columnHeader:

    // Determines the text that will be displayed on the calendar’s column headings.
    //columnHeaderFormat:

    // Programmatically generates text that will be displayed on the calendar’s column headings.
    //columnHeaderText:

    // Programmatically generates HTML that will be injected on the calendar’s column headings.
    //columnHeaderHtml:

    //----- TIME-AXIS SETTINGS -----
    // The frequency for displaying time slots.
    //slotDuration:

    // The frequency that the time slots should be labelled with text.
    //slotLabelInterval:

    // Determines the text that will be displayed within a time slot.
    //slotLabelFormat:

    // Determines the first time slot that will be displayed for each day.
    //minTime:

    // Determines the last time slot that will be displayed for each day. In line with the discussion about the Event object, it is important to stress that this should be specified as an exclusive end time.
    //maxTime:

    // Determines how far forward the scroll pane is initially scrolled.
    //scrollTime:

    //----- CALLBACKS -----
    // A hook for modifying a day cell’s DOM.
    //dayRender:

    //----- METHODS -----
    // Programatically scroll the current view to the given time.
    //scrollToTime:

    // In month view, whether dates in the previous or next month should be rendered at all.
    //showNonCurrentDates:

    //===== Date Nav Links =====
    // Turns various datetime text into clickable links that the user can use for navigation. Activated by setting the navLinks settings to true.

    //Determines if day names and week names are clickable.
    //navLinks:

    // Determines what happens upon a day heading nav-link click.
    //navLinkDayClick:

    // Determines what happens upon a week-number nav-link click.
    //navLinkWeekClick:

    //===== Week Numbers =====
    // Display week numbers in various parts of your calendar views.

    // Determines if week numbers should be displayed on the calendar.
    weekNumbers: false,

    // Determines the styling for week numbers in Month and DayGrid views.
    //weekNumbersWithinDays:

    // The method for calculating week numbers that are displayed with the weekNumbers setting.
    //weekNumberCalculation:

    // The heading text for week numbers. Also affects weeks in date formatting.
    //weekLabel:

    //===== Date Clicking & Selecting =====
    // Detect when the user clicks on dates or times. Give the user the ability to select multiple dates or time slots with their mouse or touch device.

    // Allows a user to highlight multiple days or timeslots by clicking and dragging.
    selectable: true,

    // Whether to draw a “placeholder” event while the user is dragging.
    //selectMirror:

    // Whether clicking elsewhere on the page will cause the current selection to be cleared.
    unselectAuto: true,

    // A way to specify elements that will ignore the unselectAuto option.
    unselectCancel: '',

    // Determines whether the user is allowed to select periods of time that are occupied by events.
    //selectOverlap:

    // Limits user selection to certain windows of time.
    //selectConstraint:

    // Exact programmatic control over where the user can select.
    //selectAllow:

    // The minimum distance the user’s mouse must travel after a mousedown, before a selection is allowed.
    //selectMinDistance:

    //----- CALLBACKS -----
    // Triggered when the user clicks on a date or a time.
    //dateClick:

    // Triggered when a date/time selection is made.
    //select:

    // Triggered when the current selection is cleared.
    //unselect:

    //-----METHODS -----
    // A method for programmatically selecting a period of time. It accepts two different signatures:
    //Calendar::select

    // A method for programmatically clearing the current selection.
    //Calendar::unselect

    //===== Now Indicator =====
    // You can display a marker that represents the exact current time in both TimeGrid view and Timeline view.

    // Whether or not to display a marker indicating the current time.
    //nowIndicator:

    // Explicitly sets the “today” date of the calendar. This is the day that is normally highlighted in yellow.
    //now:

    //=====  Business Hours =====
    // Emphasize a view’s “business hours” (aka “working hours”).

    // Emphasizes certain time slots on the calendar. By default, Monday-Friday, 9am-5pm.
    //businessHours:

    // The main businessHours settings can be applied more granularly to individual resources via the businessHours property on the Resource input
    //businessHours-per-resource:


    //===== Event Sources =====

    // An array of Event Objects that will be displayed on the calendar.
    //events (as an array)

    // A URL of a JSON feed that the calendar will fetch Event Objects from.
    //events (as a json feed)
    //events: events_url,

    // A custom function for programmatically generating Events.
    //events (as a function)

    // FullCalendar can display events from a public Google Calendar.
    //events from Google Calendar

    // An “event source” is anything that provides FullCalendar with data about events.
    //Event Source Object

    // A way to specify multiple event sources.
    //eventSources:

    // A function that gets called when fetching succeeds. It can transform the response. Gets called for any type of Event source.
    //eventSourceSuccess:

    // Called when any of the event sources fails. Probably because an AJAX request failed.
    //eventSourceFailure:

    //----- EVENT REQUESTING -----
    // A parameter of this name will be sent to each JSON event feed. It describes the start of the interval being fetched.
    //startParam:

    //A parameter of this name will be sent to each JSON event feed. It describes the exclusive end of the interval being fetched.
    //endParam:

    // A parameter of this name will be sent to each JSON event feed. It describes the timezone of the startParam and endParam values, as well as the desired timezone of the returned events.
    //timeZoneParam:

    // Determines when event fetching should occur.
    //lazyFetching:

    //Triggered when event or resource fetching starts/stops.
    //loading:

    //----- METHODS -----

    // Retrieves all Event Source Objects.
    //Calendar::getEventSources

    // Retrieves a specific Event Source Object.
    //Calendar::getEventSourceById

    // Dynamically adds an event source.
    //Calendar::addEventSource

    // Refetches events from all sources and rerenders them on the screen.
    //Calendar::refetchEvents

    // Causes this event source to fetch its event data again.
    //EventSource::refetch

    // Removes all events associated with this source and prevents it from being fetched again.
    //EventSource::remove


    //===== Event Display =====
    // How to control the appearance of events on your calendar.

    // Sets the background and border colors for all events on the calendar.
    eventColor: 'transparent',

    // Sets the background color for all events on the calendar.
    //eventBackgroundColor:

    // Sets the border color for all events on the calendar.
    //eventBorderColor:

    // Sets the text color for all events on the calendar.
    //eventTextColor:

    // Determines the time-text that will be displayed on each event.
    //eventTimeFormat:

    // Whether or not to display the text for an event’s date/time.
    //displayEventTime:

    // Whether or not to display an event’s end time.
    //displayEventEnd:

    // When an event’s end time spans into another day, the minimum time it must be in order for it to render as if it were on that day.
    //nextDayThreshold:

    // Determines the ordering events within the same day.
    //eventOrder:

    // When to render multiple asynchronous event sources in an individual or batched manner.
    //progressiveEventRendering:

    //----- CALLBACKS -----

    // Triggered while an event is being rendered. A hook for modifying its DOM.
    //eventRender:

    // Triggered after an event has been placed on the calendar in its final position.
    //eventPositioned:

    // Called before an event’s element is removed from the DOM.
    //eventDestroy:tim

    //----- METHODS -----
    // Rerenders all events on the calendar.
    //Calendar::rerenderEvents

    //===== Event Clicking & Hovering =====
    // Handers to know when an event has been clicked or hovered over.

    // Triggered when the user clicks an event.
    //eventClick:

    // Triggered when the user mouses over an event. Similar to the native mouseenter.
    //eventMouseEnter:

    // Triggered when the user mouses out of an event. Similar to the native mouseleave.
    //eventMouseLeave:

    //===== International =====

    // 
    //locale:

    // The direction that elements in the calendar are rendered. Either left-to-right or right-to-left.
    //dir:

    // jQuery UI theme
    theme: false,
    // The day that each week begins.
    firstDay: 0, // 日曜日
    // 週モード(fixed,liquid,variable)
    weekMode: 'fixed',

    timeFormat: 'HH:mm', // <- time format within events
    smallTimeFormat: 'H時', // <- time format of axis (week-view and day-view)

    slotMinutes: 30,
    snapMinutes: 30,
    //firstHour: 8,
    //minTime: '00:00:00',
    //maxTime: '24:00:00',

    //monthNames: [
    //  I18n.t('js.calendar.month_names.jan'), I18n.t('js.calendar.month_names.feb'), I18n.t('js.calendar.month_names.mar'),
    //  I18n.t('js.calendar.month_names.apr'), I18n.t('js.calendar.month_names.may'), I18n.t('js.calendar.month_names.jun'),
    //  I18n.t('js.calendar.month_names.jul'), I18n.t('js.calendar.month_names.aug'), I18n.t('js.calendar.month_names.sep'),
    //  I18n.t('js.calendar.month_names.oct'), I18n.t('js.calendar.month_names.nov'), I18n.t('js.calendar.month_names.dec')
    //],
    monthNames: [
      '1月', '2月', '3月', '4月', '5月', '6月',
      '7月', '8月', '9月', '10月', '11月', '12月',
    ],
    monthNamesShort: [
      '1月', '2月', '3月', '4月', '5月', '6月',
      '7月', '8月', '9月', '10月', '11月', '12月',
    ],
    dayNames: [
      '日', '月', '火', '水', '木', '金', '土',
    ],
    dayNamesShort: [
      '日', '月', '火', '水', '木', '金', '土',
    ],

    selectHelper: true,

    ignoreTimezone: false,
    editable: false,
    droppable: false,

    displayEventEnd: true,

    eventColor: 'rgba(150,150,150,0.2)',
    disableDragging: true
  }
}
