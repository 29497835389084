$(document).on('ready turbolinks:load', function() {
    $("select[name='fixture[room_id]']").change(function() {
        var val = $("select[name='fixture[room_id]']").val();
        if (val == "") {
            $("select[name='fixture[fixture_type]'] option[value='item']").prop('selected',true);
            $(".stock_limit").slideDown();
            $("select[name='fixture[unit]'] option[value='event']").prop('selected',true);
            $("select[name='fixture[unit]']").prop('disabled', true);
        } else{
            $("select[name='fixture[fixture_type]'] option[value='incidental']").prop('selected',true);
            $(".stock_limit").slideUp();
            $("input[name='fixture[stock_quantity]']").val(1);
            $("input[name='fixture[limit]']").val(1);
            $("select[name='fixture[unit]']").prop('disabled', false);
        }
        var fixture_type = $("select[name='fixture[fixture_type]']").val();
        $("#fixture_type_hidden").val(fixture_type);
        $("#fixture_type_label").text($("select[name='fixture[fixture_type]'] option:selected").text());
        var unit = $("select[name='fixture[unit]']").val();
        $("#unit_hidden").val(unit);
        $("select[name='fixture[unit]']").trigger('change');
    })
    $("select[name='fixture[room_id]']").trigger('change');

    $("select[name='fixture[unit]']").change(function() {
        var unit = $("select[name='fixture[unit]']").val();
        $("#unit_hidden").val(unit);
        if (unit == "event") {
            $(".use_seconds").slideUp();
        } else{
            $(".use_seconds").slideDown();
        }
    })
    $("select[name='fixture[unit]']").trigger('change');
});
  
$(window).on('pageshow', function() {
    $("select[name='fixture[room_id]']").trigger('change');
    $("select[name='fixture[unit]']").trigger('change');
});
  