$(document).on('turbolinks:load', function() {

  var slideSwitch = function() {
    var active = $('#slideshow li.active')
    if ($(active).length == 0) {
      active = $('#slideshow li:last')
    }
    var next = null
    if ($(active).next().length) {
      next = $(active).next()
    } else {
      next = $('#slideshow li:first')
    }
    console.log("next", next)
    $(active).addClass('last-active')
    //$(next).css({opacity: 0.0}).addClass('active').animate( { opacity: 1.0 }, 1000, function() {
    //  $(active).removeClass('active last-active')
    //  return
    //})
    return
  }

  if ($('body').hasClass('welcome index')) {
    //setInterval(slideSwitch, 5000)
  }
});
