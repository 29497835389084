$(document).on('ready turbolinks:load', function() {
  $("#room_is_group").on('change', room_is_group_changed);
  $("#room_is_group").trigger('change');

  $("#add_rooms_into_group").on('click', function() {
    $("select[name='rooms_in_facility[]'] option:selected").each(function() {
      $("select[name='room[room_group_members][]']").append($('<option>').html($(this).text()).val($(this).val()))
    });
    var item = $("select[name='room[room_group_members][]']").children().sort(sort_by_text);
    $("select[name='room[room_group_members][]']").append(item);
    $("select[name='rooms_in_facility[]'] option:selected").remove();
  })

  $("#remove_rooms_from_group").on('click', function() {
    $("select[name='room[room_group_members][]'] option:selected").each(function() {
      $("select[name='rooms_in_facility[]']").append($('<option>').html($(this).text()).val($(this).val()))
    });
    var item = $("select[name='rooms_in_facility[]']").children().sort(sort_by_text);
    $("select[name='rooms_in_facility[]']").append(item);
    $("select[name='room[room_group_members][]'] option:selected").remove();
  })

  $("select[name='room[reservation_method]']").on('change', reservation_method_changed);
  $("select[name='room[reservation_method]']").trigger('change');

  $("select[name='room[lock_type]']").change(function() {
    var val = $("select[name='room[lock_type]']").val();
    if (val == "remotelock") {
      $("input[name='room[validity_period_margin_before]']").val($("input[name='room[remotelock_margin_before]']").val())
      $("input[name='room[validity_period_margin_after]']").val($("input[name='room[remotelock_margin_after]']").val())
    } else if (val == "igloo") {
      $("input[name='room[validity_period_margin_before]']").val($("input[name='room[igloo_margin_before]']").val())
      $("input[name='room[validity_period_margin_after]']").val($("input[name='room[igloo_margin_after]']").val())
    }
    lock_type_load_setting();
  })

  $("input[name='room[validity_period_margin_before]']").on('change', function() {
    var val = $("select[name='room[lock_type]']").val();
    var rVal = $("input[name='room[validity_period_margin_before]']").val();
    if (rVal != "") {
      if (val == "remotelock") {
        $("input[name='room[remotelock_margin_before]']").val($("input[name='room[validity_period_margin_before]']").val());
      } else if (val == "igloo") {
        $("input[name='room[igloo_margin_before]']").val($("input[name='room[validity_period_margin_before]']").val());
      }
    }
  });
  $("input[name='room[validity_period_margin_before]']").trigger('change');

  $("input[name='room[validity_period_margin_after]']").on('change', function() {
    var val = $("select[name='room[lock_type]']").val();
    var rVal = $("input[name='room[validity_period_margin_after]']").val();
    if (rVal != "") {
      if (val == "remotelock") {
        $("input[name='room[remotelock_margin_after]']").val($("input[name='room[validity_period_margin_after]']").val());
      } else if (val == "igloo") {
        $("input[name='room[igloo_margin_after]']").val($("input[name='room[validity_period_margin_after]']").val());
      }
    }
  });
  $("input[name='room[validity_period_margin_after]']").trigger('change');
  $("select[name='room[lock_type]']").trigger('change');

  lock_type_load_setting();

  // モーダルダイアログ内にselect name="user_id"がある時のみ限定で処理する (現在は部屋の情報画面と部屋の一覧画面のみ)
  //   (issue#467 空室カレンダー画面で動作しないように修正)
  if ($(".modal-body select[name='user_id']").length > 0) {
    $("select[name='user_id']").change(function() {
      // 部屋の数だけダイアログが出来てしまうため、すべてのダイアログの選択を見るように
      $("select[name='user_id']").each(function() {
        // 同一modal(ダイアログ)上のfooterを変更対象とする
        var footer = $(this).parents('.modal-content').find(".modal-footer-button");
        if (footer != null){
          var val = $(this).val();
          if (val == ''){
            footer.hide();
          } else {
            footer.show();
          }
        }
      });
    })
    $("select[name='user_id']").trigger('change');
  }
  
  $('#room_preview').on('click', function() {
    pre_edit_room_submit();
    return true
  })
  $('#room_save').on('click', function() {
    var val_group = $("select[name='room[room_group_members][]']").text();
    var val_method = $("select[name='room[reservation_take_method]']").val();
    var val_oldmethod = $("input[name='room[reservation_take_method]']").val();
    var val_parent = $("input[name='room[group_parent]']").val();
 
    let msgFlg = false;
    if ($("#room_is_group").prop('checked')) {
      if (val_method == "first") {
        if ( val_group.indexOf("[抽]") != -1) {
          msgFlg = true;
        }
      } else {
        if ( val_group.indexOf("[先]") != -1) {
          msgFlg = true;
        }
      }
    }
    else {
      if (val_parent !='0' && val_method != val_oldmethod)
      {
        msgFlg = true;
      }
    }

    let checkMoveFlg = true;
    if (msgFlg == true) {
      checkMoveFlg = window.confirm('属する部屋グループ・その部屋グループに属する部屋など、\n関連する部屋について同様の予約の受付方法で更新されます。\n\nよろしいですか？');
    }
    if(checkMoveFlg == false) {
      return false
    }

    pre_edit_room_submit();
    return true
  })
});

$(window).on('pageshow', function() {
  $("#room_is_group").trigger('change');
  $("select[name='room[reservation_method]']").trigger('change');
  $("select[name='room[lock_type]']").trigger('change');
  // モーダルダイアログ内にselect name="user_id"がある時のみ限定で処理する (現在は部屋の情報画面と部屋の一覧画面のみ)
  //   (issue#467 空室カレンダー画面で動作しないように修正)
  if ($(".modal-body select[name='user_id']").length > 0) {
    $("select[name='user_id']").trigger('change');
  }
  lock_type_load_setting();
});

function room_is_group_changed() {
  if ($("#room_is_group").prop('checked')) {
    $(".room_group").slideDown();
    /*$(".display_raffle").slideUp();
    $(".display_first").slideDown();
    document.getElementById("room_reservation_take_method").querySelector("option[value='first']").selected = true;*/
  } else {
    $(".room_group").slideUp();
    /*$(".display_raffle").slideDown();
    $(".display_first").slideUp();*/
  }
}

function reservation_method_changed() {
  var val = $("select[name='room[reservation_method]']").val();
  if (val == "optional") {
    $(".room_select_unit").slideDown()
  } else {
    $(".room_select_unit").slideUp()
  }
}

function lock_type_load_setting() {
  var val = $("select[name='room[lock_type]']").val();
  if (val == "none") {
    $(".room_lock_serial").slideUp()
    $(".room_lock_master_pin").slideUp();
    $(".room_validity_period_margin_before").slideUp()
    $(".room_validity_period_margin_after").slideUp()
  } else {
    $(".room_lock_serial").slideDown()
    $(".room_validity_period_margin_before").slideDown();
    $(".room_validity_period_margin_after").slideDown();

    if (val == "remotelock") {
      $(".room_lock_master_pin").slideDown();
      $(".room_lock_serial_igloo").slideUp();
      $(".room_lock_serial_remotelock").slideUp();
      $("#validity_period_margin_before_hour").slideUp();
      $("#validity_period_margin_after_hour").slideUp();
      $("#validity_period_margin_before_min").slideDown();
      $("#validity_period_margin_after_min").slideDown();
    } else if (val == "igloo") {
      $(".room_lock_master_pin").slideUp();
      $(".room_lock_serial_igloo").slideDown();
      $(".room_lock_serial_remotelock").slideUp();
      $("#validity_period_margin_before_min").slideUp();
      $("#validity_period_margin_after_min").slideUp();
      $("#validity_period_margin_before_hour").slideDown();
      $("#validity_period_margin_after_hour").slideDown();
    }
  }
}

function pre_edit_room_submit() {
  $('#room_room_group_members option').prop("selected", true);
}

function sort_by_text(a, b) {
  var val_a1 = a.text.substr(0, 3);
  var val_a2 = a.text.slice(3).trim();
  var val_b1 = b.text.substr(0, 3);
  var val_b2 = b.text.slice(3).trim();

  var val = $("select[name='room[reservation_take_method]']").val();
  if (val == "first") {
    if (val_a1 <= val_b1 && val_a2 > val_b2) {
      if (val_a1 != val_b1) {
        return -1;
      } else {
        return 1;
      }
    } else if (val_a1 <= val_b1 && val_a2 < val_b2) {
      return -1;
    } else {
      return 0;
    }
  } else {
    if (val_a1 >= val_b1 && val_a2 > val_b2) {
      if (val_a1 != val_b1) {
        return -1;
      } else {
        return 1;
      }
    } else if (val_a1 >= val_b1 && val_a2 < val_b2) {
      return -1;
    } else {
      return 0;
    }
  }
}