$(document).on('turbolinks:load', function() {
  if (g_prices.is_prices_show) { 
    // 時間枠価格表 表示画面(詳細 または 選択)

    const price_calendar_readonly_date_no = [1, 2, 3, 4, 5, 6, 7];
    const price_calendar_readonly_date_jp = ['月曜', '火曜', '水曜', '木曜', '金曜', '土曜', '日曜・祝日'];
    const price_calendar_readonly_week = no => ('#price-calendar-readonly-week' + no);
    const price_calendar = index => $(price_calendar_readonly_week(price_calendar_readonly_date_no[index]));

    const price_to_fullcalendar_event = function(price) {
      return {
        title: '\\ ' + price.yen,
        start: price.checkin,
        end: price.checkout
      }
    }

    // 時間枠価格表 表示画面(詳細 または 選択)でのカレンダー描画

    window.g_prices.show_render_calendar = function (prices) {
      for (i = 0; i < g_prices.date_types.length; i++) {
        const calendar = price_calendar(i);
        const date_type = g_prices.date_types[i];
        const events = g_prices.prices_by_date_type(prices, date_type).map(price => price_to_fullcalendar_event(price));
        calendar.fullCalendar('removeEvents');
        calendar.fullCalendar('addEventSource', events, true);
        calendar.fullCalendar('rerenderEvents');
      }
    }

    // 時間枠価格表 表示画面(詳細 または 選択)の初期化

    const params = calendar_base_params();
    Object.assign(params, {
      header: {
        left: '',
        center: '',
        right: ''
      },
      defaultView: 'agendaDay',
      minTime: '00:00:00',
      maxTime: '24:00:00',
      selectable: false,
      selectHelper: false,
      ignoreTimezone: false,
      editable: false,
      droppable: false,
      displayEventEnd: true,
      eventColor: 'gray',
      eventTextColor: "black",
    });

    for (let i = 0; i < price_calendar_readonly_date_no.length; i++) {
      let calendar = price_calendar(i);
      calendar.empty();
      params.columnHeaderText = function() { return price_calendar_readonly_date_jp[i] };
      calendar.fullCalendar(params);
    }

    if (g_prices.is_select_price) {
      // 時間枠価格表 選択
      g_prices.select_prices_init();
    } else {
      // 時間枠価格表 詳細
      g_prices.show_update_prices(room_id, start_on_value);
    }
  }
});

$(document).on('turbolinks:before-cache', function() {
  if (g_prices.is_prices_show) {
    if (g_prices.is_select_price) {
      // 時間枠価格表 選択 終了処理
      g_prices.select_prices_fini();
    }
  }
});
