$(document).on('ready turbolinks:load', function() {

  $("input[id='facility_cancel_enabled']").change(function() {
    if ($("input[id='facility_cancel_enabled']").prop("checked")) {
      $(".facility_cancel_list").slideDown();
    } else {
      $(".facility_cancel_list").slideUp();
    }
  })
  $("input[id='facility_cancel_enabled']").trigger('change');

  $("select[id='facility_cancel_period_type']").change(function() {
    var val = $("select[id='facility_cancel_period_type']").val();
    if (val == "before_days_hours") {
      $(".facility_cancel_X").slideDown();
    } else {
      $(".facility_cancel_X").slideUp();
    }
  })
  $("select[id='facility_cancel_period_type'").trigger('change');

  $("select[name='facility[multiple_reservation_limit_type]']").on('change', function() {
    var val = $("select[name='facility[multiple_reservation_limit_type]']").val();
    if (val == "none") {
      $(".facility_multiple_reservation_limit_number").slideUp()
    } else {
      $(".facility_multiple_reservation_limit_number").slideDown()
    }
  })
  $("select[name='facility[multiple_reservation_limit_type]']").trigger('change');

  $("select[id='facility_reservation_reception_end_type']").change(function() {
    var val = $("select[id='facility_reservation_reception_end_type']").val();
    if (val != "none") {
      $(".facility_reservation_reception_end_N").slideDown();
    } else {
      $(".facility_reservation_reception_end_N").slideUp();
    }
  })
  $("select[id='facility_reservation_reception_end_type'").trigger('change');

  $("select[name='facility[raffle_application_start_type]']").on('change', raffle_application_start_type_changed);
  $("select[name='facility[raffle_application_start_type]']").trigger('change');

  $("select[name='facility[raffle_date_type]']").on('change', raffle_date_type_changed);
  $("select[name='facility[raffle_date_type]']").trigger('change');
});

$(window).on('pageshow', function() {
  $("input[id='facility_cancel_enabled']").trigger('change');
  $("select[id='facility_cancel_period_type']").trigger('change');
  $("select[name='facility[multiple_reservation_limit_type]']").trigger('change');

  $("select[name='facility[raffle_application_start_type]']").trigger('change');
  $("select[name='facility[raffle_date_type]']").trigger('change');
});

function raffle_application_start_type_changed() {
  var val = $("select[name='facility[raffle_application_start_type]']").val();
  if (val == "month_days") {
    $(".facility_raffle_application_start_X_value").slideDown()
  } else {
    $(".facility_raffle_application_start_X_value").slideUp()
  }
}

function raffle_date_type_changed() {
  var val = $("select[name='facility[raffle_date_type]']").val();
  if (val == "month_days") {
    $(".facility_raffle_date_Y_value").slideDown()
  } else {
    $(".facility_raffle_date_Y_value").slideUp()
  }
}


