$(document).on('turbolinks:load', function() {
  $('#facility_images,#room_images').on('change', function(event) {
    var field = $(event.target).parent().find('.preview_images')
    var files = event.target.files
    var def = (new $.Deferred()).resolve()
    $.each(files, function(i, file) {
      def = def.then(function() {
        return previewImage(field, file)
      })
    })
  })

  var previewImage = function(field, file) {
    var reader = new FileReader()
    var img = new Image()
    var def = $.Deferred()
    reader.onload = function(event) {
      $(field).append(img)
      img.src = event.target.result
      def.resolve(img)
    }
    reader.readAsDataURL(file)
    return def.promise()
  }
})
